import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChallengeHomeComponent} from './challenge-home/challenge-home.component';
import {ChallengeRoutingModule} from './challenge-routing.module';
import {UtilityModule} from '../utility/utility.module';
import {ChallengeActiveComponent} from './challenge-active/challenge-active.component';
import {ChallengeAddnewComponent} from './challenge-addnew/challenge-addnew.component';
import {ChallengeRequestsComponent} from './challenge-requests/challenge-requests.component';
import {ChallengeSearchComponent} from './challenge-search/challenge-search.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import {FitboardIndividualComponent} from './fitboard-individual/fitboard-individual.component';
import {LabelValueCardComponent} from './label-value-card/label-value-card.component';
import {FitboardTeamComponent} from './fitboard-team/fitboard-team.component';
import {PlaystationIndividualComponent} from './playstation-individual/playstation-individual.component';
import {PlaystationTeamComponent} from './playstation-team/playstation-team.component';
import {PlaystationTeamViewComponent} from './playstation-team-view/playstation-team-view.component';
import {PlaystationTeamEditComponent} from './playstation-team-edit/playstation-team-edit.component';
import {PlaystationTeamViewActiveInactiveComponent} from './playstation-team-view-active-inactive/playstation-team-view-active-inactive.component';
import {PlaystationTeamItemComponent} from './playstation-team-item/playstation-team-item.component';
import {PlaystationTeamSelectedComponent} from './playstation-team-selected/playstation-team-selected.component';
import {PlaystationTeamCreateComponent} from './playstation-team-create/playstation-team-create.component';
import {PlaystationTeamJoinComponent} from './playstation-team-join/playstation-team-join.component';
import {PlaystationTeamChallangeSearchComponent} from './playstation-team-challange-search/playstation-team-challange-search.component';
import {PlaystationTeamChallangeAddComponent} from './playstation-team-challange-add/playstation-team-challange-add.component';
import {PlaystationTeamChallangeRequestComponent} from './playstation-team-challange-request/playstation-team-challange-request.component';
import {PlaystationTeamChallangeAdd2Component} from './playstation-team-challange-add2/playstation-team-challange-add2.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PlaystationTeamAddMemberComponent} from './playstation-team-add-member/playstation-team-add-member.component';
import {PlaystationTeamJoinRequestComponent} from './playstation-team-join-request/playstation-team-join-request.component';
import {PlaystationCompanyHomeComponent} from './playstation-company-home/playstation-company-home.component';
import {FitboardCompanyHomeComponent} from './fitboard-company-home/fitboard-company-home.component';
import {TeamChallengeActiveComponent} from './team-challenge-active/team-challenge-active.component';

@NgModule({
 declarations: [
  ChallengeHomeComponent,
  ChallengeActiveComponent,
  ChallengeAddnewComponent,
  ChallengeRequestsComponent,
  ChallengeSearchComponent,
  FitboardIndividualComponent,
  LabelValueCardComponent,
  FitboardTeamComponent,
  PlaystationIndividualComponent,
  PlaystationTeamComponent,
  PlaystationTeamViewComponent,
  PlaystationTeamEditComponent,
  PlaystationTeamViewActiveInactiveComponent,
  PlaystationTeamItemComponent,
  PlaystationTeamSelectedComponent,
  PlaystationTeamCreateComponent,
  PlaystationTeamJoinComponent,
  PlaystationTeamChallangeSearchComponent,
  PlaystationTeamChallangeAddComponent,
  PlaystationTeamChallangeRequestComponent,
  PlaystationTeamChallangeAdd2Component,
  PlaystationTeamAddMemberComponent,
  PlaystationTeamJoinRequestComponent,
  PlaystationCompanyHomeComponent,
  FitboardCompanyHomeComponent,
  TeamChallengeActiveComponent,
 ],
 imports: [
  CommonModule,
  ChallengeRoutingModule,
  UtilityModule,
  DpDatePickerModule,
  FormsModule,
  ReactiveFormsModule,
 ],
})
export class ChallengeModule {}
