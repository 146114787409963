import {Component, OnInit} from '@angular/core';

@Component({
 selector: 'app-playstation-team-challange-add2',
 templateUrl: './playstation-team-challange-add2.component.html',
 styleUrls: ['./playstation-team-challange-add2.component.scss'],
})
export class PlaystationTeamChallangeAdd2Component implements OnInit {
 constructor() {}

 ngOnInit() {}
}
