import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {Environment} from 'src/environments/environment';
import {getUserId} from 'src/app/shared/util/auth-utils';
@Component({
	selector: 'app-playstation-team-challange-search',
	templateUrl: './playstation-team-challange-search.component.html',
	styleUrls: ['./playstation-team-challange-search.component.scss'],
})
export class PlaystationTeamChallangeSearchComponent implements OnInit {
	public teamData = [];
	public paramData = {};
	imageUrl = Environment.groupImagechallengeUrl;
	public isLoading = false;
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {}

	ngOnInit() {
		debugger;
		if (this.isMyTeamSearch) {
			this.isLoading = true;
			this._clientService
				.getChallengeRequest('api/Groups?AdminId=' + getUserId())
				.subscribe(res => {
					this.isLoading = false;
					if (res) {
						this.teamData = res;
					}
				});
		} else {
			this.paramData['ChallengerGroupId'] = this._route.snapshot.params[
				'ChallengerGroupId'
			];
			this.searchOpponentTeam('a');
		}
	}

	public searchOpponentTeam(searchVal) {
		let url = 'api/Groups?sreachTearm=' + searchVal;
		if (!this.isMyTeamSearch) {
			url = `api/Groups/SeachTeamToChallenge?userid=${getUserId()}&sreachTearm=${searchVal ||
				'a'}`;
		}
		this.isLoading = true;
		this._clientService.getChallengeRequest(url).subscribe(res => {
			this.isLoading = false;
			if (res) {
				if (this.isMyTeamSearch) {
					this.teamData = res.filter(d => d.GroupAdminId == getUserId());
				} else {
					this.teamData = res.filter(
						d =>
							d.GroupId != this.paramData['ChallengerGroupId'] &&
							d.GroupAdminId != getUserId(),
					);
				}
			}
		});
	}
	public onClickHandler(data) {
		if (this.isMyTeamSearch) {
			this._router.navigate([
				'/challenge/playstation/team/search-opponent-team',
				{ChallengerGroupId: data.GroupId},
			]);
		} else {
			data['ChallengerGroupId'] = this.paramData['ChallengerGroupId'];
			this._router.navigate([
				'/challenge/playstation/team/add',
				{data: JSON.stringify(data)},
			]);
		}
	}
	public get isMyTeamSearch() {
		return this._router.url == '/challenge/playstation/team/search-my-team';
	}
}
