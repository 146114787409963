import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-goal-list',
	templateUrl: './goal-list.component.html',
	styleUrls: ['./goal-list.component.scss'],
})
export class GoalListComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
