import {IHealth} from '../interface/health';
export function getAuthUUID() {
	const uuid =
		localStorage.getItem('ilm_uuid') || 'eaf786ca-810f-11e9-bc42-526af7764f64';
	console.log(`UUID -->> key: ilm_uuid -- value:  ${uuid}`);
	return uuid;
}

export function getAuthToken() {
	const token = localStorage.getItem('ilm_token');
	console.log(`token -->> key: ilm_token -- value:  ${token}`);
	return token;
}
export function getChallengesAuthUUID() {
	let user: any = localStorage.getItem('user') || setLocalStorage();
	user = <IHealth.UserAuth>JSON.parse(localStorage.getItem('user'));
	return user.TokenEntity.AuthToken;
}

export function getChallengesAuthToken() {
	let user: any = localStorage.getItem('user') || setLocalStorage();
	user = <IHealth.UserAuth>JSON.parse(localStorage.getItem('user'));
	return user.TokenEntity.AuthToken;
}

export function getUserId() {
	let user: any = localStorage.getItem('user') || setLocalStorage();
	user = <IHealth.UserAuth>JSON.parse(localStorage.getItem('user'));
	return user.TokenEntity.UserId;
}

export function getRoleId() {
	let user: any = localStorage.getItem('user') || setLocalStorage();
	user = <IHealth.UserAuth>JSON.parse(localStorage.getItem('user'));
	return user.RoleId;
}
export function getCorporateId() {
	let user: any = localStorage.getItem('user') || setLocalStorage();
	user = <IHealth.UserAuth>JSON.parse(localStorage.getItem('user'));
	return user.CorporateId;
}

function setLocalStorage() {
	localStorage.setItem(
		'user',
		`{
        "UserId": 1,
        "RoleId": 3,
        "RoleName": "SingleUser",
        "CorporateId": 1,
        "CorporateName": "ABSLI",
        "FirstName": "Hemant",
        "LastName": "Deore",
        "EmailId": "hemant.deore@gmail.com",
        "ContactNo": "9000000000",
        "TokenEntity": {
          "TokenId": 0,
          "UserId": 1,
          "AuthToken": "5f22c7d0-323a-443f-b060-9766474df3ab",
          "IssuedOn": "29-08-2019 17:25:43",
          "ExpiresOn": "30-08-2020 17:25:43"
        }
      }`,
	);
}
