import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-familiy-member-detail',
	templateUrl: './familiy-member-detail.component.html',
	styleUrls: ['./familiy-member-detail.component.sass'],
})
export class FamiliyMemberDetailComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
