import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IHealth} from '../shared/interface/health';
import {GraphMessaging} from '../shared/util/constants';
import {RewardContainerComponent} from '../reward/reward-container/reward-container.component';
import {GoalHomeComponent} from './goal-home/goal-home.component';
import {GoalListComponent} from './goal-list/goal-list.component';
import {GoalGridComponent} from './goal-grid/goal-grid.component';

const routes: Routes = [
 {
  path: '',
  redirectTo: '/goal',
  pathMatch: 'full',
 },
 {
  path: 'goal',
  component: GoalHomeComponent,
  children: [
   {
    path: 'list',
    component: GoalListComponent,
   },
   {
    path: 'grid',
    component: GoalGridComponent,
   },
  ],
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class GoalRoutingModule {}
