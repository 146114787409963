import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-goal-grid',
	templateUrl: './goal-grid.component.html',
	styleUrls: ['./goal-grid.component.scss'],
})
export class GoalGridComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
