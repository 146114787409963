import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { ClientService } from 'src/app/shared/http/client.service';
import { IHealth } from 'src/app/shared/interface/health';
import { getAuthUUID } from '../../shared/util/auth-utils';

@Component({
	selector: 'app-health-score',
	templateUrl: './health-score.component.html',
	styleUrls: ['./health-score.component.scss'],
})
export class HealthScoreComponent implements OnInit {
	public healthScoreChart = [];
	public segments = [];
	public data: IHealth.IHealthScore = <IHealth.IHealthScore>{};
	constructor(public _clientService: ClientService) { }

	ngOnInit() {
		this.segments = [
			{
				title: 'Life Style Score',
				dataKey: 'LifeStyle',
				color: '#97281E',
			},
			{
				title: 'Body Profile',
				dataKey: 'BodyProfile',
				color: '#E46713',
			},
			{
				title: 'Diet Score',
				dataKey: 'Diet',
				color: '#053C6D',
			},
			{
				title: 'Stress Score',
				dataKey: 'Stress',
				description: 'Stress score, lower the better',
				color: '#E77817',
			},
		];

		this._clientService
			.postRequest('fetchhealthscore', {
				UUID: getAuthUUID(),
			})
			.subscribe(data => {
				console.debug(data);
				this.data = <IHealth.IHealthScore>(data ? data : {});
				this.generatePieChart(this.data.HealthScore);
			});
	}

	private generatePieChart(percentage: number): void {
		percentage = percentage ? percentage : 0;
		this.healthScoreChart = new Chart('healthScoreCanvas', {
			type: 'doughnut',
			data: {
				datasets: [
					{
						backgroundColor: ['#97281E', '#E77817'],
						data: [percentage, 100 - percentage],
					},
				],
			},
			plugins: [
				{
					beforeDraw: function (chart) {
						var width = chart.chart.width,
							height = chart.chart.height,
							ctx = chart.chart.ctx;
						ctx.restore();
						var fontSize = (height / 100).toFixed(2);
						ctx.font = 25 + "px 'Montserrat', sans-serif";
						ctx.fillStyle = '#000';
						ctx.textBaseline = 'middle';

						var text = percentage + '/100',
							textX = Math.round((width - ctx.measureText(text).width) / 2),
							textY = height / 2;
						ctx.fillText(text, textX, textY);
						ctx.save();
					},
				},
			],
			options: {
				legend: {
					display: false,
				},
				tooltips: { enabled: false },
				hover: { mode: null },
				responsive: true,
				maintainAspectRatio: false,
				cutoutPercentage: 80,
			},
		});
	}

	public getFormatedDate(date: string): string {
		return moment(date).format('DD MMM YYYY');
	}
}
