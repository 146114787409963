import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';

@Component({
	selector: 'app-profile-home',
	templateUrl: './profile-home.component.html',
	styleUrls: ['./profile-home.component.scss'],
})
export class ProfileHomeComponent implements OnInit {
	public routeColorMatcher: string = '#00C0F3';
	public statsColor: string = '#00C0F3';
	constructor(private router: Router) {
		this.router.events.subscribe((v: NavigationEnd) => {
			if (v.url == '/profile/stats') {
				this.routeColorMatcher = '#00C0F3';
				this.statsColor = '#E77817';
			} else if (v.url == '/profile/actions') {
				this.routeColorMatcher = '#fff';
				this.statsColor = '#97291E';
			} else {
				this.routeColorMatcher = 'transparent';
				this.statsColor = '#FDB92A';
			}
		});
	}

	ngOnInit() {}
}
