import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionsRoutingModule } from './actions-routing.module';
import { ActionForTodayComponent } from './action-for-today/action-for-today.component';

@NgModule({
	declarations: [ActionForTodayComponent],
	imports: [CommonModule, ActionsRoutingModule],
})
export class ActionsModule { }
