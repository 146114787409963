import {Component, OnInit, Input} from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
	@Input() text: string = 'Submit';
	@Input() bg: string = 'Submit';
	@Input() color: string = 'Submit';
	@Input() url: string = 'Submit';
	constructor() {}

	ngOnInit() {}
}
