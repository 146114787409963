import {Component, OnInit} from '@angular/core';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {Location} from '@angular/common';
import {getUserId} from 'src/app/shared/util/auth-utils';
import {HttpParams} from '@angular/common/http';
@Component({
	selector: 'app-playstation-team-view',
	templateUrl: './playstation-team-view.component.html',
	styleUrls: ['./playstation-team-view.component.scss'],
})
export class PlaystationTeamViewComponent implements OnInit {
	public type = 'AdminId';
	public isActive: boolean = true;
	public data: any;
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {}
	ngOnInit() {
		const url = window.location.href;
		if (url.includes(';')) {
			const httpParams = new HttpParams({fromString: url.split(';')[1]});
			this.type = httpParams.get('type');
		}
		if (this.type) {
			this._clientService
				.getChallengeRequest('api/Groups?' + this.type + '=' + getUserId())
				.subscribe(res => {
					if (res) {
						if (this.type == 'MemberId') {
							this.data = res.filter(d => d.GroupAdminId != getUserId());
						} else {
							this.data = res.filter(d => d.GroupAdminId == getUserId());
						}
					}
				});
		}
	}

	public changeType($event) {
		this.type = $event.target.value;
		this.setActive(this.isActive);
	}
	public setActive(bool) {
		if (bool) {
			this.isActive = true;
			this._router.navigate([
				'/challenge/playstation/team/view/active',
				{type: this.type},
			]);
		} else {
			this.isActive = false;
		}
		this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
			this._router.navigate([
				'/challenge/playstation/team/view/inactive',
				{type: this.type},
			]);
		});
	}
}
