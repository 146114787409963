import {Component, OnInit} from '@angular/core';

@Component({
 selector: 'app-action-for-today',
 templateUrl: './action-for-today.component.html',
 styleUrls: ['./action-for-today.component.scss'],
})
export class ActionForTodayComponent implements OnInit {
 constructor() {}

 ngOnInit() {}
}
