import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Environment} from 'src/environments/environment';
import {Observable, throwError} from 'rxjs';
import {getAuthToken, getChallengesAuthToken} from '../util/auth-utils';
import {map, catchError, retry} from 'rxjs/operators';
import {AppUtilsService} from '../util/app-utils.service';

@Injectable({
	providedIn: 'root',
})
export class ClientService {
	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthToken()}`,
		}),
	};
	public httpChallengesOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			Token: getChallengesAuthToken(),
		}),
	};
	public fileOptions = {
		headers: new HttpHeaders({
			mimeType: 'multipart/form-data',
		}),
	};
	constructor(private http: HttpClient, private _appUtils: AppUtilsService) {}

	/**
	 * generic application specific get request
	 * @param url http://13.233.142.94:4433/api/ + url string
	 */
	public postRequest(url: string, data): Observable<any> {
		// console.log(Environment.baseUrl + url);
		return this.http.post(Environment.baseUrl + url, data, this.httpOptions);
	}
	/**
	 * generic application specific get request
	 * @param url http://13.233.142.94:4433/api/ + url string
	 */
	public postChallengeRequest(url: string, data): Observable<any> {
		return this.http
			.post(Environment.challengeUrl + url, data, this.httpChallengesOptions)
			.pipe(catchError(this.errorHandler.bind(this)));
	}
	/**
	 * generic application specific get request
	 * @param url http://13.233.142.94:4433/api/ + url string
	 */
	public putChallengeRequest(url: string, data): Observable<any> {
		return this.http
			.put(Environment.challengeUrl + url, data, this.httpChallengesOptions)
			.pipe(catchError(this.errorHandler.bind(this)));
	}
	/**
	 * generic application specific get request
	 * @param url http://13.233.142.94:4433/api/ + url string
	 */
	public getChallengeRequest(url: string): Observable<any> {
		return this.http
			.get(Environment.challengeUrl + url, this.httpChallengesOptions)
			.pipe(catchError(this.errorHandler.bind(this)));
	}
	/**
	 * generic application specific get request
	 * @param url http://13.233.142.94:4433/api/ + url string
	 */
	public uploadFile(url: string, data): Observable<any> {
		return this.http.post(Environment.baseUrl + url, data, this.fileOptions);
	}
	public errorHandler(err: HttpErrorResponse) {
		console.log(err);
		if (err.status === 401) {
			this.setUser().subscribe(() => {
				this._appUtils.showSnackBar({
					message: 'Refreshing page to update token.',
				});
				window.location.reload();
			});
		}
		else if (err.status === 400) {
		 // handle validation error
		 let validationErrorDictionary = err.error.ModelState;
		 for (var fieldName in validationErrorDictionary) {
		   if (validationErrorDictionary.hasOwnProperty(fieldName)) {
			//  this.errors.push(validationErrorDictionary[fieldName]);

			 this._appUtils.showSnackBar({
				message: validationErrorDictionary[fieldName],
				type: 'danger',
			});
			return throwError(err);
		   }
		 }
		}	
		this._appUtils.showSnackBar({
			message: err.message,
			type: 'danger',
		});
		return throwError(err);
	}
	setUser() {
		return this.postChallengeRequest('api/Authenticate/Login', {
			EmailId: 'hemant.deore@gmail.com',
			ContactNo: '9000000000',
		}).pipe(
			map(data => {
				localStorage.setItem('user', JSON.stringify(data));
				return data;
			}),
		);
	}
}
