import {Component, OnInit} from '@angular/core';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {Location} from '@angular/common';
import {getUserId} from 'src/app/shared/util/auth-utils';
import {Environment} from 'src/environments/environment';
@Component({
 selector: 'app-fitboard-team',
 templateUrl: './fitboard-team.component.html',
 styleUrls: ['./fitboard-team.component.scss'],
})
export class FitboardTeamComponent implements OnInit {
 public teamDashboardData: {
  ParticipatedChallengeCount?: number;
  WonChallengeCount?: number;
  ActiveChallengeCount?: number;
  UpcomingChallengeCount?: number;
  TrophiesWonCount?: number;
  CompletedCount?: number;
  TotalPointsEarned?: number;
  AcceptRejectPoints?: number;
 } = {};
 public data = {
  participated: {
   url: 'GroupChallenges/PaticipatedByUser',
   text: 'Challenges Participated',
  },
  won: {
   url: 'GroupChallenges/WonByUser',
   text: 'Challenges Won',
  },
  upcoming: {
   url: 'GroupChallenges/UpcomingByUser',
   text: 'Upcoming Challenges',
  },
  ongoing: {
   url: 'GroupChallenges/OngoingByUser',
   text: 'Ongoing Challenges',
  },
  twon: {
   url: '',
   text: 'Trophies Won',
  },
  completed: {
   url: 'GroupChallenges/CompeletedByUser',
   text: 'Challenges Completed',
  },
 };
 public groupsData = [];

 public imageUrl = Environment.groupImagechallengeUrl;
 constructor(
  private _clientService: ClientService,
  private _route: ActivatedRoute,
  private _router: Router,
  private _location: Location,
  private _appUtils: AppUtilsService,
 ) {}

 ngOnInit() {
  this._clientService
   .getChallengeRequest(
    'api/Users/GetGroupDashboardData?UserId=' + getUserId(),
   )
   .subscribe(res => {
    if (res) {
     this.teamDashboardData = res;
    }
   });
  this._clientService
   .getChallengeRequest('api/Groups?AdminId=' + getUserId())
   .subscribe(res => {
    if (res) {
     this.groupsData = res;
    }
   });
 }
 openChallenges(data) {
  console.log(data);
  this._router.navigate([
   '/challenge/fitboard/team/challenges/',
   {data: JSON.stringify(data)},
  ]);
 }

 onTeamClick(id) {
  this._router.navigate(['/challenge/playstation/team/selected/', {id: id}]);
 }
}
