import {Component, OnInit} from '@angular/core';

@Component({
 selector: 'app-links',
 templateUrl: './links.component.html',
 styleUrls: ['./links.component.sass'],
})
export class LinksComponent implements OnInit {
 constructor() {}

 ngOnInit() {}
}
