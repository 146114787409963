import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IHealth} from '../shared/interface/health';
import {GraphChartComponent} from './graph-chart/graph-chart.component';
import {GraphHomeComponent} from './graph-home/graph-home.component';
import {GraphMessaging} from '../shared/util/constants';

const routes: Routes = [
 {
  path: 'graph/',
  redirectTo: 'graph/sleep',
  pathMatch: 'full',
 },
 {
  path: 'graph',
  component: GraphHomeComponent,
  children: [
   {
    path: 'sleep',
    component: GraphChartComponent,
    data: <IHealth.RouteData>GraphMessaging.sleep,
   },
   {
    path: 'steps',
    component: GraphChartComponent,
    data: <IHealth.RouteData>GraphMessaging.steps,
   },
   {
    path: 'water',
    component: GraphChartComponent,
    data: <IHealth.RouteData>GraphMessaging.water,
   },
   // {
   //   path: "calories",
   //   component: GraphChartComponent,
   //   data: <IHealth.RouteData>GraphMessaging.calories
   // },
   // {
   //   path: "commute",
   //   component: GraphChartComponent,
   //   data: <IHealth.RouteData>GraphMessaging.commute
   // },
   {
    path: 'meditation',
    component: GraphChartComponent,
    data: <IHealth.RouteData>GraphMessaging.meditation,
   },
   {
    path: 'sit',
    component: GraphChartComponent,
    data: <IHealth.RouteData>GraphMessaging.sit,
   },
   {
    path: 'stand',
    component: GraphChartComponent,
    data: <IHealth.RouteData>GraphMessaging.stand,
   },
   {
    path: 'activehours',
    component: GraphChartComponent,
    data: <IHealth.RouteData>GraphMessaging.activehours,
   },
  ],
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class GraphRoutingModule {}
