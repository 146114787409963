import {Component, OnInit} from '@angular/core';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {IHealth} from 'src/app/shared/interface/health';
import {Location} from '@angular/common';
import * as moment from 'moment';
import {getUserId} from 'src/app/shared/util/auth-utils';
import {Environment} from 'src/environments/environment';
@Component({
	selector: 'app-team-challenge-active',
	templateUrl: './team-challenge-active.component.html',
	styleUrls: ['./team-challenge-active.component.scss'],
})
export class TeamChallengeActiveComponent implements OnInit {
	public label = 'Ongoing Challenges';
	public url = 'GroupChallenges/OngoingByUser';
	public activeChallengesData: IHealth.ActiveChallenge[] = [];

	public userDetais = {};
	public iconPath = Environment.challengeUrl;
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {}

	ngOnInit() {
		this._route.params.subscribe(params => {
			console.log('Recieved paramters: ', params['data']);
			if (this.IsJsonString(params['data'])) {
				let data = JSON.parse(params['data']);

				this.label = data.text;
				// this.url = data.url;
			}
			this.loadActiveChallenges();
		});
	}
	private IsJsonString(str) {
		try {
			var json = JSON.parse(str);
			return typeof json === 'object';
		} catch (e) {
			return false;
		}
	}
	private loadActiveChallenges() {
		this._clientService
			.getChallengeRequest(`api/${this.url}?UserId=${getUserId()}`)
			.subscribe(data => {
				if (data && data.length) {
					this.activeChallengesData = data;
				}
			});
	}
	public getFormattedDate(d) {
		return moment(d, 'DD-MM-YYYY').format('DD MMMM YYYY');
	}
}
