import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-reward-container',
	templateUrl: './reward-container.component.html',
	styleUrls: ['./reward-container.component.sass'],
})
export class RewardContainerComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
