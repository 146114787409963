import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChartMessagingComponent} from './chart-messaging/chart-messaging.component';

@NgModule({
 declarations: [ChartMessagingComponent],
 imports: [CommonModule],
 exports: [ChartMessagingComponent],
})
export class ChartMessagingModule {}
