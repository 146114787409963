import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {IHealth} from 'src/app/shared/interface/health';
import {getAuthUUID} from 'src/app/shared/util/auth-utils';
import {ClientService} from 'src/app/shared/http/client.service';
import * as moment from 'moment';

@Component({
	selector: 'app-report-detail',
	templateUrl: './report-detail.component.html',
	styleUrls: ['./report-detail.component.sass'],
})
export class ReportDetailComponent implements OnInit {
	public isLoading: boolean;
	public healthRecord: IHealth.IHealthRecord;
	private headerLable: string;
	private familyMemberName: string;
	private reportType: string;

	constructor(
		private _route: ActivatedRoute,
		private _clientService: ClientService, // private _router: Router, // private _location: Location, // private _appUtils: AppUtilsService
	) {}

	ngOnInit() {
		this.isLoading = true;
		this.loadData(this._route.snapshot.params.id);
		document.body.classList.add('ehr');
	}

	OnDestroy() {
		document.body.classList.remove('ehr');
	}

	private loadData(id: number) {
		const param = {UUID: getAuthUUID(), ID: id};
		this._clientService
			.postRequest('fetchHealthRecordByID', param)
			.subscribe((data: IHealth.IHealthRecord) => {
				console.debug(data);
				this.healthRecord = data;
				console.debug(this.healthRecord.HealthRecordFilesList);
				this.isLoading = false;
				// this.setData()
				// this.loadReportType();
				// this.loadFamilyMemberName();
				// this.loadFamilyMemberName();
			});
	}

	// private loadFamilyMemberName() {
	//   this._clientService
	//     .postRequest(`fetchAllHealthRecord`, { UUID: getAuthUUID() })
	//     .subscribe((data: IHealth.IHealthRecord[]) => {
	//       const currentReport = data.find(e => e.ID === this.healthRecord.ID);
	//       this.familyMemberName = currentReport.ClientFamilyDetailName;
	//     });
	// }

	// private loadReportType() {
	//   this._clientService
	//     .postRequest(`FetchHealthRecordTypes`, {})
	//     .subscribe(data => {
	//       const currentReport = data.find(
	//         e => e.ID === this.healthRecord.HealthRecordTypeID
	//       );
	//       this.reportType = currentReport.Name;
	//     });
	// }

	private getFormattedDate(date: string) {
		return moment(date).format('DD MMM YYYY');
	}
}
