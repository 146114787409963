import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {getUserId} from 'src/app/shared/util/auth-utils';
import {Environment} from 'src/environments/environment';
@Component({
	selector: 'app-playstation-team-join',
	templateUrl: './playstation-team-join.component.html',
	styleUrls: ['./playstation-team-join.component.scss'],
})
export class PlaystationTeamJoinComponent implements OnInit {
	public teamData = [];
	public selectedGroupIds = [];

	public imageUrl = Environment.groupImagechallengeUrl;
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {}

	ngOnInit() {
		this.searchTeam('');
	}

	public searchTeam(searchVal) {
		this._clientService
			.getChallengeRequest(
				`api/Groups/SeachTeamToChallenge?userid=${getUserId()}&sreachTearm=${searchVal ||
					'a'}`,
			)
			.subscribe(res => {
				if (res) {
					this.teamData = res.filter(d => d.GroupAdminId != getUserId());
				}
			});
	}

	public addTeam(data) {
		let ind = this.selectedGroupIds.findIndex(d => d.GroupId == data.GroupId);
		if (ind < 0) {
			this.selectedGroupIds.push(data);
		} else {
			this.selectedGroupIds.splice(ind, 1);
		}
	}

	public isSelected(data) {
		return this.selectedGroupIds.find(d => d.GroupId == data.GroupId)
			? true
			: false;
	}
	public joinTeam() {
		if (!this.selectedGroupIds || !this.selectedGroupIds.length) {
			this._appUtils.showSnackBar({
				message: 'Please select atleast one group',
				type: 'info',
			});
			return;
		} else {
			for (let o of this.selectedGroupIds) {
				let obj = {
					GroupId: o['GroupId'],
					GroupAdminId: o['GroupAdminId'],
					MemberId: getUserId(),
				};
				this._clientService
					.postChallengeRequest('api/Groups/UserRequestsAdminToJoinGroup', obj)
					.subscribe(res => {
						if (res) {
							this._appUtils.showSnackBar({
								message: 'Request sent successfully',
							});
						}
					});
			}
		}
	}
}
