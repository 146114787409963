import { Component, OnInit } from '@angular/core';
import { IHealth } from 'src/app/shared/interface/health';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-graph-home',
	templateUrl: './graph-home.component.html',
	styleUrls: ['./graph-home.component.sass'],
})
export class GraphHomeComponent implements OnInit {
	public routeData: IHealth.RouteData;
	public tabData = [
		{
			link: '/graph/sleep',
			class: 'icon-sleep',
		},
		{
			link: '/graph/steps',
			class: 'icon-steps',
		},
		{
			link: '/graph/water',
			class: 'icon-waterintake',
		},
		// {
		//   link: "/graph/calories",
		//   class: "icon-calories"
		// },
		// {
		//   link: "/graph/commute",
		//   class: "icon-commute"
		// },
		{
			link: '/graph/meditation',
			class: 'icon-meditation',
		},
		{
			link: '/graph/sit',
			class: 'icon-sit',
		},
		{
			link: '/graph/stand',
			class: 'icon-stand',
		},
		{
			link: '/graph/activehours',
			class: 'icon-activehours',
		},
		// hd comment for local
	];

	constructor(private _route: ActivatedRoute) { }

	ngOnInit() {
		this.loadRouteData();
	}

	private loadRouteData(): void {
		this._route.data.subscribe(v => {
			this.routeData = <IHealth.RouteData>v;
		});
	}
}
