import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GraphHomeComponent} from './graph-home/graph-home.component';
import {GraphChartComponent} from './graph-chart/graph-chart.component';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {ChartMessagingModule} from '../chart-messaging/chart-messaging.module';
import {GraphRoutingModule} from './graph-routing.module';

@NgModule({
 declarations: [GraphHomeComponent, GraphChartComponent],
 imports: [
  HttpClientModule,
  BrowserModule,
  CommonModule,
  GraphRoutingModule,
  ChartMessagingModule,
 ],
})
export class GraphModule {}
