import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IHealth} from '../shared/interface/health';
import {GraphMessaging} from '../shared/util/constants';
import {RewardContainerComponent} from '../reward/reward-container/reward-container.component';
import {AnalysisHomeComponent} from '../analysis/analysis-home/analysis-home.component';
import {AnalysisDetailComponent} from '../analysis/analysis-detail/analysis-detail.component';
import {GoalListComponent} from '../goal/goal-list/goal-list.component';
import {PlaystationHomeComponent} from './playstation-home/playstation-home.component';
import {CompeteComponent} from './compete/compete.component';
import {ChallengeComponent} from './challenge/challenge.component';

const routes: Routes = [
 {
  path: '',
  redirectTo: '/playstation',
  pathMatch: 'full',
 },
 {
  path: 'playstation',
  component: PlaystationHomeComponent,
  children: [
   {
    path: 'compete',
    component: CompeteComponent,
   },
   {
    path: 'challenge',
    component: ChallengeComponent,
   },
  ],
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class PlaystationRoutingModule {}
