import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { HealthPrintUtilService } from 'src/app/shared/util/health-print.service';
import { HealthEnums } from 'src/app/shared/util/enums';

@Component({
	selector: 'app-sleep',
	templateUrl: './sleep.component.html',
	styleUrls: ['./sleep.component.scss'],
})
export class SleepComponent implements OnInit {
	public sleepChart = [];
	public data = [];
	constructor(private _helathUtil: HealthPrintUtilService) { }
	ngOnInit() {
		// this.renderChart(this.data)
		this.loaddata();
	}
	public loaddata() {
		this._helathUtil
			.getdata(HealthEnums.HealthPrint.sleep)
			.subscribe(response => {
				this.data = response;
				this.renderChart(response);
			});
	}

	private minutesToHours(hour: any) {
		return !isNaN(hour) ? Math.round(hour / 60) : hour;
	}
	private renderChart(data): void {
		var weeks = {};
		let lastMonth = this.getWeeklyData(data[0]).splice(0, 5);
		let currentMonth = this.getWeeklyData(data[1]).splice(0, 5);
		let chartData = {
			type: 'line',
			data: {
				labels: [1, 2, 3, 4, 5],
				datasets: [
					{
						label:
							data[0].dataPoints.length && data[0].dataPoints[0].date
								? this.getFormatedDate(data[0].dataPoints[0].date)
								: 'NA',
						data: lastMonth,
						backgroundColor: 'transparent',
						borderColor: '#03bef0',
						borderWidth: 2,
						showLines: false,
					},
					{
						label:
							data[1].dataPoints.length && data[1].dataPoints[0].date
								? this.getFormatedDate(data[1].dataPoints[0].date)
								: 'NA',
						data: currentMonth,
						backgroundColor: 'transparent',
						borderColor: '#0a3a67',
						borderWidth: 2,
						showLines: false,
					},
				],
			},
			options: {
				showLine: false,
				showLabel: false,
				legend: {
					display: true,
					position: 'right',
					labels: {
						usePointStyle: true,
						padding: 10,
						fontSize: 10,
					},
				},
				scales: {
					xAxes: [
						{
							barPercentage: 1.0,
							categoryPercentage: 1.0,
							barThickness: 6,
							maxBarThickness: 20,
							minBarLength: 2,
							gridLines: {
								color: 'rgba(0, 0, 0, 0)',
							},
							scaleLabel: {
								display: true,
								labelString: 'Week',
							},
						},
					],
					yAxes: [
						{
							gridLines: {
								color: 'rgba(0, 0, 0, 0)',
							},
							scaleLabel: {
								display: true,
								labelString: 'Hrs',
							}, 
							ticks: {
								beginAtZero: true
							}
						},
					],
				},
			},
		};
		this.sleepChart = new Chart('sleepCanvas', chartData);
	}

	public getWeeklyData(data) {
		var weeks = {};
		data.dataPoints.forEach(v => {
			let w = this.weekOfMonth(moment(v.date, 'DD/MM/YYYY'));
			weeks[w] = weeks[w] ? [...weeks[w], v.point] : [v.point];
		});
		let groupedData = [];
		for (let key in weeks) {
			groupedData.push(
				this.minutesToHours(weeks[key].reduce((a, b) => a + b, 0)),
			);
		}
		return groupedData;
	}
	public weekOfMonth(input) {
		const firstDayOfMonth = input.clone().startOf('month');
		const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

		const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

		return Math.ceil((input.date() + offset) / 7);
	}

	public getFormatedDate(date: string): string {
		return moment(date, 'DD/MM/YYYY').format('MMM');
	}
	public getFormatedDay(date: string): string {
		return moment(date, 'DD/MM/YYYY').format('DD');
	}

	public getTotalSleep() {
		let total = {
			currentMonth: 0,
			lastMonth: 0,
		};
		const data = this.data;
		if (data.length && data[0].dataPoints.length) {
			let minutes = data[0].dataPoints.reduce((a, b) => a + b.point, 0);
			total.currentMonth = this.minutesToHours(minutes);
		}
		if (data.length && data[1].dataPoints.length) {
			let minutes = this.data[1].dataPoints.reduce((a, b) => a + b.point, 0);
			total.lastMonth = this.minutesToHours(minutes);
		}
		return total;
	}

	public sleepDiffrence() {
		const {
			currentMonth,
			lastMonth,
		}: { currentMonth: number; lastMonth: number } = this.getTotalSleep();
		const prefix = lastMonth < currentMonth ? '+' : '';
		return `${prefix} ${currentMonth - lastMonth}`;
	}

	public getSleepPercetage() {
		const data = this.data;
		if (data.length && data[0].dataPoints.length) {
			const totalDays = data[0].dataPoints.length;
			const sleepHours = 8;
			const requiredSleepAmount = sleepHours * totalDays;
			const actualSleepAmount = this.minutesToHours(
				this.data[0].dataPoints.reduce((a, b) => a + b.point, 0),
			);
			return Math.round((actualSleepAmount / requiredSleepAmount) * 100);
		}
	}

	public getSleepProgressWidth() {
		return `${this.getSleepPercetage()}%`;
	}

	// public getTotalSleep(data) {
	//   return data.dataPoints.reduce((a, b) => a + b.point, 0)
	// }
}
