import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-goal-home',
	templateUrl: './goal-home.component.html',
	styleUrls: ['./goal-home.component.scss'],
})
export class GoalHomeComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
