import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-playstation-team-join-request',
	templateUrl: './playstation-team-join-request.component.html',
	styleUrls: ['./playstation-team-join-request.component.scss'],
})
export class PlaystationTeamJoinRequestComponent implements OnInit {
	public isLoading: boolean;
	public challenges: any;
	constructor() {}

	ngOnInit() {}
}
