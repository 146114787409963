import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HealthPrintHomeComponent} from './health-print-home/health-print-home.component';
import {HealthScoreComponent} from './health-score/health-score.component';
import {BmiComponent} from './bmi/bmi.component';
import {SleepComponent} from './sleep/sleep.component';
import {StepsComponent} from './steps/steps.component';
import {HealthPrintRoute as HealthPrintRouteModule} from './health-print-routing.module';
import {ChartComponent} from './chart/chart.component';

@NgModule({
 declarations: [
  HealthPrintHomeComponent,
  HealthScoreComponent,
  BmiComponent,
  SleepComponent,
  StepsComponent,
  ChartComponent,
 ],
 imports: [CommonModule, HealthPrintRouteModule],
})
export class HealthPrintModule {}
