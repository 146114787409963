import {Component, OnInit, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
	@Input('label') label: string = 'No header';
	@Input('enableBack') enableBack: boolean = true;
	@Input('bgColor') bgColor: string = 'white';

	constructor(
		private _router: Router,
		private _location: Location,
		private _route: ActivatedRoute,
	) {}
	ngOnInit() {
		console.debug(this.enableBack);
	}
	backClicked() {
		this._location.back();
	}
}
