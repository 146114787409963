import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './spinner/spinner.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './header/header.component';
import {ButtonComponent} from './button/button.component';

@NgModule({
 declarations: [SpinnerComponent, HeaderComponent, ButtonComponent],
 imports: [CommonModule, BrowserAnimationsModule],
 exports: [SpinnerComponent, HeaderComponent, ButtonComponent],
})
export class UtilityModule {}
