import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IHealth} from '../shared/interface/health';
import {GraphMessaging} from '../shared/util/constants';
import {ProfileHomeComponent} from './profile-home/profile-home.component';
import {RewardContainerComponent} from '../reward/reward-container/reward-container.component';
import {AnalysisHomeComponent} from '../analysis/analysis-home/analysis-home.component';
import {AnalysisDetailComponent} from '../analysis/analysis-detail/analysis-detail.component';
import {GoalListComponent} from '../goal/goal-list/goal-list.component';

const routes: Routes = [
 {
  path: '',
  redirectTo: '/profile',
  pathMatch: 'full',
 },
 {
  path: 'profile',
  component: ProfileHomeComponent,
  children: [
   {
    path: 'rewards',
    component: RewardContainerComponent,
   },
   {
    path: 'stats',
    component: AnalysisDetailComponent,
   },
   {
    path: 'actions',
    component: GoalListComponent,
   },
  ],
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class ProfileRoutingModule {}
