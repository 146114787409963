import { Component, OnInit } from '@angular/core';
import { HealthEnums } from 'src/app/shared/util/enums';
import { ActivatedRoute, Router } from '@angular/router';
import { Environment } from 'src/environments/environment';
@Component({
	selector: 'app-health-print-home',
	templateUrl: './health-print-home.component.html',
	styleUrls: ['./health-print-home.component.sass'],
})
export class HealthPrintHomeComponent implements OnInit {
	public showDiv = 'true';
	public HealthPrintUrl = Environment.HealthPrintUrl;
	public data = [
		{
			label: 'Steps',
			borderColor: ['#E77817', '#053C6D'],
			url: HealthEnums.HealthPrint.steps,
			icon: 'steps-icon',
			description:
				'We were built to walk far and never to sit on chair or lie on couch for hours',
			progress: 'walk-progress',
			// multiIconCount: 6,
		},
		{
			label: 'Sleep',
			// borderColor: ['#03bef0', '#0a3a67'],
			borderColor: ['#E77817', '#053C6D'],
			url: HealthEnums.HealthPrint.sleep,
			icon: 'sleep-icon',
			description:
				'Sleep is divided into two broad types, non rapid eye movement (Non-REM or NREM) Sleep and rapid eye movement (REM) sleep.',
			progress: 'sleep-progress',
			// multiIconCount: 5,
		},
		{
			label: 'Sit',
			// borderColor: ['#FF5A1D', '#FFA430'],
			borderColor: ['#E77817', '#053C6D'],
			url: HealthEnums.HealthPrint.sit,
			icon: 'sit-icon',
			description:
				'Sitting for long period of time during the day can cause number of health problems, try to take breaks as frequently as possible.',
			progress: 'sit-progress',
			// multiIconCount: 5,
		},
		{
			label: 'Stand',
			// borderColor: ['#8A56AC', '#D47FA6'],
			borderColor: ['#E77817', '#053C6D'],
			url: HealthEnums.HealthPrint.stand,
			icon: 'stand-icon',
			description:
				'Getting up and walking around throughout the day, helps you to stay active physically and mentally.',
			progress: 'stand-progress',
			// multiIconCount: 5,
		},
		{
			label: 'Water',
			// borderColor: ['#4666E5', '#4EBDEF'],
			borderColor: ['#E77817', '#053C6D'],
			url: HealthEnums.HealthPrint.water,
			icon: 'water-icon',
			description:
				'Drinking plenty of water to stay hydrated is as important as getting good night’s sleep and physical activity.',
			progress: 'water-progress',
			// multiIconCount: 5,
		},
		{
			label: 'Meditation',
			// borderColor: ['#FF5A1D', '#FFA430'],
			borderColor: ['#E77817', '#053C6D'],
			url: HealthEnums.HealthPrint.med,
			icon: 'meditation-icon',
			description:
				'Meditate at least once a day to promote emotional health, reduce stress, increase concentration and many other health benefits.',
			progress: 'meditation-progress',
			// multiIconCount: 5,
		},
	];

	constructor(private route: ActivatedRoute, private router: Router) { }

	ngOnInit() {
		const userId = this.route.snapshot.queryParams['userid'];
		let shows = this.route.snapshot.queryParams['showDiv'];
		if (userId) {
			localStorage.setItem('ilm_uuid', userId);
		}
		if (shows != undefined) { this.showDiv = shows; }
		console.log(this.showDiv);

	}
	showClick() {
		// this.showDiv = 'true';
		// this.router.navigate(['/health-print/bmi/',{ userid: localStorage.getItem('ilm_uuid'), showDiv: 'true' }]);
		// this.router.navigate(['/health-print/bmi/'], { queryParams: { userid: localStorage.getItem('ilm_uuid'), showDiv: 'true' }}) //This works for query parameter.
		// this.router.navigateByUrl('https://icicighi.page.link/healthPrintUAT');
	}
}	
