import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-playstation-home',
	templateUrl: './playstation-home.component.html',
	styleUrls: ['./playstation-home.component.scss'],
})
export class PlaystationHomeComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
