import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/shared/http/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtilsService } from 'src/app/shared/util/app-utils.service';
import { Location } from '@angular/common';
import { getCorporateId, getUserId } from 'src/app/shared/util/auth-utils';
import { IHealth } from 'src/app/shared/interface/health';
import { forkJoin } from 'rxjs';
@Component({
	selector: 'app-playstation-team-add-member',
	templateUrl: './playstation-team-add-member.component.html',
	styleUrls: ['./playstation-team-add-member.component.scss'],
})
export class PlaystationTeamAddMemberComponent implements OnInit {
	public selectedUser = [];
	public isSearchEnabled: boolean = false;
	public userData: IHealth.User[] = [];
	public groupId;
	public isLoading: boolean = false;
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {
		this._route.params.subscribe(params => {
			console.log('Recieved paramters: ', params);
			if (params) {
				this.groupId = params.groupId;
			}
		});
	}

	ngOnInit() {
		let postData = {
			CorporateId: getCorporateId(),
			SearchTerm: '',
		};
		this._clientService
			.postChallengeRequest('api/Users/GetAllByFilter', postData)
			.subscribe(data => {
				if (data) {
					this.userData = data.filter(e => e.Id != getUserId());
				}
			});
	}
	public onSearchChange(str: string) {
		if (true || str) {
			let postData = {
				CorporateId: 1,
				SearchTerm: str || '',
			};
			this._clientService
				.postChallengeRequest('api/Users/GetAllByFilter', postData)
				.subscribe(data => {
					if (data) {
						this.userData = data.filter(e => e.Id != getUserId());
					}
				});
		}
	}
	public addMember(id: number) {
		let indexOf = this.selectedUser.indexOf(id);
		if (indexOf > -1) {
			this.selectedUser.splice(indexOf, 1);
		} else {
			this.selectedUser.push(id);
		}
	}

	public addedMembers() {
		let list = [];
		for (let id of this.selectedUser) {
			let postData = {
				GroupId: parseInt(this.groupId),
				GroupAdminId: getUserId(),
				MemberId: id,
			};
			list.push(
				this._clientService.postChallengeRequest(
					'/api/Groups/AdminInviteToJoinGroup',
					postData,
				),
			);
		}
		this.isLoading = true;
		forkJoin(list).subscribe(data => {
			if (data) {
				this.isLoading = false;
				this._appUtils.showSnackBar({
					message: 'Invitation sent successfully.',
					type: 'success',
				});

				this._router.navigate([
					'/challenge/fitboard/team/challenges/',
					{
						data: JSON.stringify({
							url: 'GroupChallenges/PaticipatedByUser',
							text: 'Challenges Participated',
						}),
					},
				]);
			}
		});
	}
}
