import {Component, OnInit, Input, ElementRef} from '@angular/core';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {IHealth} from 'src/app/shared/interface/health';
import {trigger, transition, style, animate} from '@angular/animations';

@Component({
	selector: 'app-alert-dialog',
	templateUrl: './alert-dialog.component.html',
	styleUrls: ['./alert-dialog.component.sass'],
	animations: [
		trigger('dialog', [
			transition('void => *', [
				style({transform: 'scale3d(.3, .3, .3)'}),
				animate(100),
			]),
			transition('* => void', [
				animate(100, style({transform: 'scale3d(.0, .0, .0)'})),
			]),
		]),
	],
})
export class AlertDialogComponent implements OnInit {
	public alertData: IHealth.IAlert;
	public show: boolean;
	constructor(
		private _apputilService: AppUtilsService,
		private el: ElementRef,
	) {}

	ngOnInit(): void {
		this.registerListener();
	}

	private registerListener() {
		this._apputilService.onAlertShow.subscribe((data: IHealth.IAlert) => {
			this.show = true;
			this.alertData = data;
		});
	}

	public onPositiveClick() {
		if (
			this.alertData.positiveFunction &&
			typeof this.alertData.positiveFunction == 'function'
		) {
			this.alertData.positiveFunction();
		}
		this.show = false;
	}
	public onNegativeClick() {
		if (
			this.alertData.negativeFunction &&
			typeof this.alertData.negativeFunction == 'function'
		) {
			this.alertData.negativeFunction();
		}
		this.show = false;
	}
}
