import { Injectable } from '@angular/core';
import { IHealth } from '../interface/health';
import * as moment from 'moment';
import { HealthEnums } from './enums';
import { ClientService } from '../http/client.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getAuthUUID } from './auth-utils';
import { parse } from 'querystring';

@Injectable({
	providedIn: 'root',
})
export class ChartUtilService {
	constructor(private _clientService: ClientService) { }

	private createPostData(
		tab: string,
		frequency: HealthEnums.Frequency,
		page: 'trends' | 'graphs' = 'trends',
		offset: number = 0,
	): object {
		let currentStartDate,
			currentEndDate,
			prevStartDate,
			prevEndDate,
			momentParam;
		switch (frequency) {
			case HealthEnums.Frequency.Daily:
				momentParam = 'week';
				break;
			case HealthEnums.Frequency.Weekly:
				momentParam = 'month';
				break;
			case HealthEnums.Frequency.Monthly:
				momentParam = 'year';
				break;
		}
		const commonData = {
			UUID: getAuthUUID(),
			tab: tab.toUpperCase(),
		};
		if (page === 'trends') {
			currentStartDate = moment()
				.subtract(1, momentParam)
				.format('DD-MM-YYYY');
			currentEndDate = moment().format('DD-MM-YYYY');
			prevStartDate = moment()
				.subtract(2, momentParam)
				.format('DD-MM-YYYY');
			prevEndDate = currentStartDate;
			return {
				...commonData,
				current: {
					dateRange: {
						startDate: currentStartDate,
						endDate: currentEndDate,
					},
					dataPoints: [],
				},
				previous: {
					dateRange: {
						startDate: prevStartDate,
						endDate: prevEndDate,
					},
					dataPoints: [],
				},
			};
		} else {
			currentStartDate = moment()
				.subtract(offset, momentParam)
				.startOf(momentParam)
				.format('DD-MM-YYYY');
			currentEndDate = moment()
				.subtract(offset, momentParam)
				.endOf(momentParam)
				.format('DD-MM-YYYY');
			return {
				...commonData,
				dateRange: {
					startDate: currentStartDate,
					endDate: currentEndDate,
				},
				dataPoints: [],
			};
		}
	}

	public getdata(
		tabName: string,
		frequency: HealthEnums.Frequency,
		pageType: HealthEnums.PageType,
		offset: number = 0,
	): Observable<any> {
		if (pageType == HealthEnums.PageType.Trend) {
			console.log('Trends');
			let data = this.createPostData(tabName, frequency, 'trends', offset);
			console.log(data);
			return this._clientService.postRequest(pageType, data).pipe(
				map(response => {
					return this.massageTrendData(response, frequency, pageType);
				}),
			);
		} else {
			console.log('Graph');
			let data = this.createPostData(tabName, frequency, 'graphs', offset);
			console.log(data);
			return this._clientService.postRequest(pageType, data).pipe(
				map(response => {
					return this.massageGraphData(response, frequency, pageType, data);
				}),
			);
		}
	}

	/**
	 * based on the frequncy data will be provided
	 * @param data input IHealth.Dtaa
	 * @param frequency Daily | Weekly | Monthly
	 */
	public massageTrendData(
		data: IHealth.ITrendData,
		frequency: HealthEnums.Frequency,
		pageType: HealthEnums.PageType,
	): IHealth.ChartDataPoint {
		switch (frequency) {
			case HealthEnums.Frequency.Daily:
				return this.getTrendDailyData(data);
			case HealthEnums.Frequency.Weekly:
				return this.getTrendWeekLyData(data);
			case HealthEnums.Frequency.Monthly:
				return this.getTrendMonthlyData(data);
		}
	}
	/**
	 * based on the frequncy data will be provided
	 * @param data input IHealth.Dtaa
	 * @param frequency Daily | Weekly | Monthly
	 */
	public massageGraphData(
		data: IHealth.IGraphData,
		frequency: HealthEnums.Frequency,
		pageType: HealthEnums.PageType,
		postData,
	): IHealth.ChartDataPoint {
		let outputData;
		switch (frequency) {
			case HealthEnums.Frequency.Daily:
				outputData = this.getGraphDailyData(data);
				break;
			case HealthEnums.Frequency.Weekly:
				outputData = this.getGraphWeeklyData(data);
				break;
			case HealthEnums.Frequency.Monthly:
				outputData = this.getGraphMonthlyData(data);
				break;
		}
		return {
			...outputData,
			goal: data.goal,
			postData,
		};
	}

	/**
	 * This function will return a daily formatted data for chart
	 * @param data input IHealth.Dtaa
	 */
	private getTrendDailyData(data: IHealth.ITrendData): IHealth.ChartDataPoint {
		let dailyData = <IHealth.ChartDataPoint>{
			curr: data.current.dataPoints.slice(0, 7).map(v => v.point),
			labels: data.current.dataPoints
				.slice(0, 7)
				.map(v => moment(v.date, 'DD-MM-YYYY').format('ddd')),
			legend: 'This week',
			vsLegend: 'Last week',
		};
		return <IHealth.ChartDataPoint>{
			...dailyData,
			prev: data.previous.dataPoints.slice(0, 7).map(v => v.point),
		};
	}

	public sortedDataPointsByDate(data: IHealth.IGraphData) {
		return data.dataPoints.sort((a, b) => {
			const dateA = moment(a.date, 'DD/MM/YYYY').format('YYYYMMDD');
			const dateB = moment(b.date, 'DD/MM/YYYY').format('YYYYMMDD');
			console.log(JSON.stringify(dateA));
			console.log(JSON.stringify(dateB));
			console.log(JSON.stringify((dateA as any) - (dateB as any)));
			return (dateA as any) - (dateB as any);
		});
	}

	private getGraphDailyData(data: IHealth.IGraphData): IHealth.ChartDataPoint {
		console.log('getGraphDailyData');
		let listOfDays = moment.weekdaysShort();
		const availableData = this.sortedDataPointsByDate(data).reduce(
			(acc, next) => {
				console.log(JSON.stringify(data));
				console.log('-----------------------------------');
				const key = moment(next.date, 'DD/MM/YYYY').format('ddd');
				acc[key] = next.point;
				console.log(acc);
				return acc;
			},
			{},
		);
		const availableDataDays = Object.keys(availableData);
		const plotData = listOfDays.reduce((acc, day) => {
			// filling in missing days with 0 value
			acc[day] = availableDataDays.includes(day) ? availableData[day] : 0;
			return acc;
		}, {});
		let ii = 0;
		const average =
			data.dataPoints.reduce((t, n) => {
				ii = ii + 1;
				console.log('Test Average = ' + Math.round(t + n.point));
				console.log(ii);
				return Math.round(t + n.point);
			}, 0) / (ii); /// (moment().day() + 1);

		return <IHealth.ChartDataPoint>{
			curr: Object.values(plotData) as any,
			labels: Object.keys(plotData) as any,
			legend: 'This week',
			vsLegend: 'Last week',
			totalPoints: data.dataPoints.length
				? data.dataPoints.slice(0, 7)[0].point
				: 0,
			average: isNaN(average) ? 0 : average,
		};
	}

	/**
	 * This function will return a weekly formatted data for chart
	 * @param data input IHealth.Dtaa
	 */
	private getTrendWeekLyData(data: IHealth.ITrendData) {
		var thisMonthsWeek: string[] = [];
		for (let i = 0; i < 4; i++) {
			thisMonthsWeek.push(
				moment()
					.subtract(i, 'weeks')
					.format('DD/MM/YYYY'),
			);
		}
		let currentData = data.current.dataPoints.filter(v =>
			thisMonthsWeek.includes(v.date),
		);
		let previous = data.previous.dataPoints.filter(v =>
			thisMonthsWeek.includes(v.date),
		);
		return <IHealth.ChartDataPoint>{
			curr: currentData.map(v => v.point),
			prev: previous.map(v => v.point),
			labels: thisMonthsWeek.map((v, i) => `W-${i + 1}`),
			legend: 'This month',
			vsLegend: 'Last Month',
		};
	}

	/**
	 * This function will return a weekly formatted data for chart
	 * @param data input IHealth.Dtaa
	 */
	private getGraphWeeklyData(data: IHealth.IGraphData) {
		var thisMonthsWeek: moment.MomentInput[] = [];
		var weeksMap = {};
		console.log('getGraphWeeklyData');
		const startOfMonth = moment().startOf('month');
		const startOfMonthss = moment().startOf('month');
		var startOfMonthsss = moment(startOfMonthsss).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');

		console.log('ddddddddddddddddddddddddddddddddd');
		console.log(startOfMonthsss);
		console.log('ddddddddddddddddddddddddddddddddd');




		const numberOfWeeks = Math.ceil(moment().daysInMonth() / 7);
		for (let index = 1; index <= numberOfWeeks; index++) {
			weeksMap[`W-${index}`] = 0;
			thisMonthsWeek.push(startOfMonth.clone().add(7 * index, 'days'));
		}
		console.log(JSON.stringify(thisMonthsWeek));
		console.log('*********************************************************');
		console.log('data = ' + JSON.stringify(data));
		thisMonthsWeek.forEach((d, index) => {
			const totalPoints = data.dataPoints.reduce((acc, v) => {
				const dpDate = moment(v.date, 'DD/MM/YYYY');
				var dd = moment(d, 'DD/MM/YYYY');
				console.log('d = ' + dd);
				var weekStart = moment(d, 'DD/MM/YYYY').subtract(7, 'days');
				var weekEnd = moment(d, 'DD/MM/YYYY').subtract(1, 'days');

				// if (dpDate.isAfter(weekStart) && dpDate.isBefore(weekEnd)) {
				console.log('weekStart = ' + weekStart.toString());
				console.log('weekEnd = ' + weekEnd.toString());
				console.log('dd = ' + dpDate.isBefore(weekEnd) + ' | point = ' + v.point + ' | point = ' + v.date);
				// }
				console.log(acc);
				console.log(acc + v.point);
				return dpDate.isSameOrAfter(weekStart) && dpDate.isSameOrBefore(weekEnd) ? (acc += v.point) : acc;
			}, 0);

			// 	return dpDate.isBefore(d) && dpDate.isAfter(moment(d, 'YYYY/MM/DD').subtract(7, 'days')) ? (acc += v.point) : acc;
			// }, 0);		

			weeksMap[`W-${(index + 1)}`] = totalPoints;
			console.log('totalPoints = ' + totalPoints)
			console.log('weeksMap')
			console.log('***********************************************************************************')
		});
		console.log(weeksMap)
		// weeksMap['W-2'] =10;

		const totalDays = data.dataPoints.length;
		const totalPoints = Object.keys(weeksMap).reduce(
			(acc, key) => acc + weeksMap[key],
			0,
		);
		const average = totalPoints / totalDays;

		return <IHealth.ChartDataPoint>{
			curr: Object.values(weeksMap) as any, //currentData.map(v => v.point),
			labels: Object.keys(weeksMap) as any, //thisMonthsWeek.map((v, i) => `W-${i + 1}`),
			legend: 'This month',
			vsLegend: 'Last Month',
			totalPoints: totalPoints,
			average: isNaN(average) ? 0 : average,
		};
	}

	/**
	 * This function will return a weekly formatted data for chart
	 * @param data input IHealth.Dtaa
	 */
	private getTrendMonthlyData(
		data: IHealth.ITrendData,
	): IHealth.ChartDataPoint {
		var thisYears: string[] = [];
		for (let i = 0; i < 12; i++) {
			thisYears.push(
				moment()
					.subtract(i, 'months')
					.format('DD/MM/YYYY'),
			);
		}
		let currentData = data.current.dataPoints.filter(v =>
			thisYears.includes(v.date),
		);

		let monthlyData = <IHealth.ChartDataPoint>{
			curr: currentData.map(v => v.point),
			labels: thisYears.map(v => moment(v, 'DD-MM-YYYY').format('MMM')),
			legend: 'This year',
			vsLegend: 'Last year',
		};
		let previous = data.previous.dataPoints.filter(v =>
			thisYears.includes(v.date),
		);
		return <IHealth.ChartDataPoint>{
			...monthlyData,
			prev: previous.map(v => v.point),
		};
	}

	/**
	 * This function will return a weekly formatted data for chart
	 * @param data input IHealth.Dtaa
	 */
	private getGraphMonthlyData(
		data: IHealth.IGraphData,
	): IHealth.ChartDataPoint {
		let listOfMonths = moment.monthsShort();
		const availableMonthWiseData = this.sortedDataPointsByDate(data).reduce(
			(acc, next) => {
				const key = moment(next.date, 'DD/MM/YYYY').format('MMM');
				!acc[key] && (acc[key] = 0);
				acc[key] += next.point;
				return acc;
			},
			{},
		);
		const availableDataMonths = Object.keys(availableMonthWiseData);
		const plotData = listOfMonths.reduce((acc, month) => {
			// filling in missing months with 0 value
			acc[month] = availableDataMonths.includes(month)
				? availableMonthWiseData[month]
				: 0;
			return acc;
		}, {});

		let monthlyData = <IHealth.ChartDataPoint>{
			curr: Object.values(plotData) as any,
			labels: Object.keys(plotData) as any,
			legend: 'This year',
			vsLegend: 'Last year',
		};
		const dayOfYear = moment().diff(moment().startOf('year'), 'days');
		const totalPoints = data.dataPoints.reduce((t, n) => t + n.point, 0);
		const average = Math.round(totalPoints / dayOfYear);

		return <IHealth.ChartDataPoint>{
			...monthlyData,
			totalPoints,
			average: isNaN(average) ? 0 : average,
		};
	}
}
