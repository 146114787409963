import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ClientService} from 'src/app/shared/http/client.service';
import {getUserId} from 'src/app/shared/util/auth-utils';

@Component({
 selector: 'app-fitboard-individual',
 templateUrl: './fitboard-individual.component.html',
 styleUrls: ['./fitboard-individual.component.scss'],
})
export class FitboardIndividualComponent implements OnInit {
 public data = {
  participated: {
   url: 'Challenges/PaticipatedByUser',
   text: 'Challenges Participated',
  },
  won: {
   url: 'Challenges/WonByUser',
   text: 'Challenges Won',
  },
  upcoming: {
   url: 'Challenges/UpcomingByUser',
   text: 'Upcoming Challenges',
  },
  ongoing: {
   url: 'Challenges/OngoingByUser',
   text: 'Ongoing Challenges',
  },
  twon: {
   url: '',
   text: 'Trophies Won',
  },
  completed: {
   url: 'Challenges/CompeletedByUser',
   text: 'Challenges Completed',
  },
 };
 public individualDashboardData: {
  ParticipatedChallengeCount?: number;
  WonChallengeCount?: number;
  ActiveChallengeCount?: number;
  UpcomingChallengeCount?: number;
  CompletedCount?: number;
  TrophiesWonCount?: number;
  TotalPointsEarned?: number;
  AcceptRejectPoints?: number;
 } = {};
 constructor(private router: Router, private _clientService: ClientService) {}

 ngOnInit() {
  this._clientService
   .getChallengeRequest(
    'api/Users/IndividualDashboardData?UserId=' + getUserId(),
   )
   .subscribe(res => {
    if (res) {
     this.individualDashboardData = res;
    }
   });
 }

 openChallenges(data) {
  console.log(data);
  this.router.navigate([
   '/challenge/fitboard/individual/challenges/',
   {data: JSON.stringify(data)},
  ]);
 }
 openRequest() {
  this.router.navigate([
   '/challenge/playstation/individual/request/',
   {data: 'data'},
  ]);
 }
 openAddnew() {
  this.router.navigate([
   '/challenge/playstation/individual/search/',
   {data: 'data'},
  ]);
 }
}
