import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {ClientService} from 'src/app/shared/http/client.service';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {Location} from '@angular/common';
import {getUserId} from 'src/app/shared/util/auth-utils';
import {HttpParams} from '@angular/common/http';

@Component({
	selector: 'app-playstation-team-view-active-inactive',
	templateUrl: './playstation-team-view-active-inactive.component.html',
	styleUrls: ['./playstation-team-view-active-inactive.component.scss'],
})
export class PlaystationTeamViewActiveInactiveComponent implements OnInit {
	public isActive: boolean = true;
	public data = [];
	public type = 'AdminId';
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {}

	ngOnInit() {
		const url = window.location.href;
		if (url.includes(';')) {
			const httpParams = new HttpParams({fromString: url.split(';')[1]});
			this.type = httpParams.get('type');
		}
		if (this.type) {
			this._clientService
				.getChallengeRequest('api/Groups?' + this.type + '=' + getUserId())
				.subscribe(res => {
					if (res) {
						if (this.type == 'MemberId') {
							this.data = res.filter(d => d.GroupAdminId != getUserId());
						} else {
							this.data = res.filter(d => d.GroupAdminId == getUserId());
						}
					}
				});
		}
	}

	onTeamClick(id) {
		this._router.navigate(['/challenge/playstation/team/selected/', {id: id}]);
	}
}
