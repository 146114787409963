import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TrendChartComponent} from './trend/trend-chart/trend-chart.component';
import {TrendHomeComponent} from './trend/trend-home/trend-home.component';
import {AppComponent} from './app.component';
import {LinksComponent} from './links/links.component';

const routes: Routes = [
 {
  path: '',
//   redirectTo: 'links/',
redirectTo: '/challenge/fitboard/individual',
  pathMatch: 'full',
 },
 {
  path: '',
  component: AppComponent,
 },
 {
  path: 'links',
  component: LinksComponent,
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class AppRoutingModule {}
