import {Component, OnInit, Output} from '@angular/core';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {Location} from '@angular/common';
import {IHealth} from 'src/app/shared/interface/health';
import {getCorporateId, getUserId} from 'src/app/shared/util/auth-utils';
import {Environment} from 'src/environments/environment';

@Component({
 selector: 'app-challenge-search',
 templateUrl: './challenge-search.component.html',
 styleUrls: ['./challenge-search.component.scss'],
})
export class ChallengeSearchComponent implements OnInit {
 public isSearchEnabled: boolean = false;
 public userData: IHealth.User[] = [];
 public imageUrl = Environment.individualImagechallengeUrl;
 constructor(
  private _clientService: ClientService,
  private _route: ActivatedRoute,
  private _router: Router,
  private _location: Location,
  private _appUtils: AppUtilsService,
 ) {}

 ngOnInit() {
  let postData = {
   CorporateId: getCorporateId(),
   SearchTerm: '',
  };
  this._clientService
   .postChallengeRequest('api/Users/GetAllByFilter', postData)
   .subscribe(data => {
    if (data) {
     this.userData = data.filter(e => e.Id != getUserId());
    }
   });
 }

 enableSearch() {
  this.isSearchEnabled = true;
 }

 public onSearchChange(str: string) {
  if (str && str.length > 2) {
   let postData = {
    CorporateId: 1,
    SearchTerm: str,
   };
   this._clientService
    .postChallengeRequest('api/Users/GetAllByFilter', postData)
    .subscribe(data => {
     if (data) {
      this.userData = data.filter(e => e.Id != getUserId());
     }
    });
  }
 }
}
