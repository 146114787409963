import {Component, OnInit} from '@angular/core';

@Component({
 selector: 'app-fitboard-company-home',
 templateUrl: './fitboard-company-home.component.html',
 styleUrls: ['./fitboard-company-home.component.scss'],
})
export class FitboardCompanyHomeComponent implements OnInit {
 constructor() {}

 ngOnInit() {}
}
