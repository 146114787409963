import {Component, OnInit} from '@angular/core';
import {IHealth} from 'src/app/shared/interface/health';
import {ClientService} from 'src/app/shared/http/client.service';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {ActivatedRoute} from '@angular/router';
import {forkJoin} from 'rxjs';
import {getAuthUUID} from 'src/app/shared/util/auth-utils';
import * as moment from 'moment';
@Component({
	selector: 'app-report-list',
	templateUrl: './report-list.component.html',
	styleUrls: ['./report-list.component.sass'],
})
export class ReportListComponent implements OnInit {
	public healthRecordList: IHealth.IHealthRecord[];
	public filteredRecordList: IHealth.IHealthRecord[];
	public isLoading: boolean;
	public reportId: number = null;
	public clientFamilyList: string[];
	public healthRecordType: string[];
	public filterRecordType: string;
	public filterFamilyMemberName: string;
	public openAccordions: number[] = [];
	public fID: number;
	public familyId: number;

	constructor(
		private _clientService: ClientService,
		private _apputilService: AppUtilsService,
		private route: ActivatedRoute,
	) {}

	ngOnInit() {
		this.route.params.subscribe(params => {
			if (params && params.fid) {
				this.reportId = params.fid;
			}
			this.loadData(params.fid);
		});
		document.body.classList.add('ehr');
	}
	private loadData(fid: number) {
		this.isLoading = true;
		let param = {};
		let url;
		if (this.reportId) {
			param = {ID: this.reportId};
			url = 'fetchHealthRecordByID';
		} else {
			param = {UUID: getAuthUUID()};
			url = 'fetchAllHealthRecord';
		}
		this._clientService
			.postRequest(url, param)
			.subscribe((data: IHealth.IHealthRecord[]) => {
				this.isLoading = false;
				this.healthRecordList = data;

				if (data) {
					this.filteredRecordList = [...data];
					this.loadFilterDropDown();
					this.toggelAccordian(0);
				}
			});
	}

	public onDelete(data: IHealth.IHealthRecord) {
		this._apputilService.showAlert(<IHealth.IAlert>{
			message: 'Are you sure, you want to delete the report?',
			showNegative: true,
			showPositive: true,
			positiveText: 'YES',
			negativeText: 'NO',
			positiveFunction: this.deleteReport.bind(this, data),
		});
	}

	public deleteReport(data: IHealth.IHealthRecord) {
		this._clientService
			.postRequest('DeleteHealthRecord', {ID: data.ID})
			.subscribe((result: IHealth.HttpResult) => {
				if (result.StatusCode == 200) {
					this._apputilService.showSnackBar(<IHealth.ISnackBar>{
						message: result.Msg,
					});
					this.loadData(this.reportId);
				}
			});
	}
	private loadFilterDropDown() {
		this.clientFamilyList = this.healthRecordList
			.map(v => v.ClientFamilyDetailName)
			.filter(v => !!v)
			.reduce(
				(unique, item) => (unique.includes(item) ? unique : [...unique, item]),
				[],
			);
		this.healthRecordType = this.healthRecordList
			.map(v => v.HealthRecordTypeName)
			.filter(v => !!v)
			.reduce(
				(unique, item) => (unique.includes(item) ? unique : [...unique, item]),
				[],
			);
	}

	public onReportTypeFilterChange(value: string) {
		if (value) {
			this.filterRecordType = value;
		} else {
			this.filterRecordType = null;
		}
		this.applyFilter();
	}
	public onFamilyMemberChange(value: string) {
		if (value) {
			this.filterFamilyMemberName = value;
		} else {
			this.filterFamilyMemberName = null;
		}
		this.applyFilter();
	}

	private applyFilter() {
		if (this.filterFamilyMemberName || this.filterRecordType) {
			if (this.filterFamilyMemberName) {
				this.filteredRecordList = [
					...this.healthRecordList.filter(
						v => v.ClientFamilyDetailName == this.filterFamilyMemberName,
					),
				];
			}
			if (this.filterRecordType) {
				this.filteredRecordList = [
					...this.healthRecordList.filter(
						v => v.HealthRecordTypeName == this.filterRecordType,
					),
				];
			}
		} else {
			this.filteredRecordList = [...this.healthRecordList];
		}
	}

	public toggelAccordian(id) {
		this.openAccordions.includes(id)
			? (this.openAccordions = this.openAccordions.filter(v => v !== id))
			: this.openAccordions.push(id);
		console.debug(this.openAccordions);
	}
	public getReportDate(value) {
		return moment(value).format('DD-MM-YYYY');
	}
}
