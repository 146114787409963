import {Component, OnInit} from '@angular/core';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {IHealth} from 'src/app/shared/interface/health';
import {trigger, transition, animate, style} from '@angular/animations';

@Component({
	selector: 'app-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss'],
	animations: [
		trigger('state', [
			transition(':enter', [
				style({bottom: '-100px', transform: 'translate(-50%, 0%) scale(0.3)'}),
				animate(
					'150ms cubic-bezier(0, 0, 0.2, 1)',
					style({
						transform: 'translate(-50%, 0%) scale(1)',
						opacity: 1,
						bottom: '20px',
					}),
				),
			]),
			transition(':leave', [
				animate(
					'150ms cubic-bezier(0.4, 0.0, 1, 1)',
					style({
						transform: 'translate(-50%, 0%) scale(0.3)',
						opacity: 0,
						bottom: '-100px',
					}),
				),
			]),
		]),
	],
})
export class SnackbarComponent implements OnInit {
	public message: IHealth.ISnackBar;

	public show = false;
	private type: string = 'success';
	constructor(private _appUtils: AppUtilsService) {}

	ngOnInit() {
		this.registerListenr();
	}

	private registerListenr() {
		this._appUtils.onShowSnackBar.subscribe((message: IHealth.ISnackBar) => {
			this.type = message.type || 'success';
			this.message = message;
			this.show = true;
			let timeOut = setTimeout(
				() => {
					this.show = false;
				},
				message.timeInSecond ? message.timeInSecond * 1000 : 3000,
			);
		});
	}
}
