import {Component, OnInit} from '@angular/core';

@Component({
 selector: 'app-playstation-team',
 templateUrl: './playstation-team.component.html',
 styleUrls: ['./playstation-team.component.scss'],
})
export class PlaystationTeamComponent implements OnInit {
 public data = {
  text: 'Ongoing Challenges',
  url: 'OngoingByUser',
 };
 constructor() {}

 ngOnInit() {}
}
