import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import { HttpClient } from '@angular/common/http';
import { IHealth } from 'src/app/shared/interface/health';
import { ClientService } from 'src/app/shared/http/client.service';
import { getAuthUUID } from '../../shared/util/auth-utils';

@Component({
	selector: 'app-bmi',
	templateUrl: './bmi.component.html',
	styleUrls: ['./bmi.component.scss'],
})
export class BmiComponent implements OnInit {
	public doughnutChart = [];
	public stressLevels = [
		'VERY LOW',
		'LOW',
		'MODERATE',
		'WARNING',
		'HIGH',
		'VERY HIGH',
	];
	public stressPoints = [16.66, 16.66, 16.66, 16.66, 16.66, 16.7];
	public stressIndex: number;
	// public data: any;
	//  public data: IHealth.IBmiScore = <any>{};
	public data: IHealth.IBmiScore = <IHealth.IBmiScore>{};

	constructor(private _clientService: ClientService) { }

	ngOnInit() {
		console.log('Test Huzzi');
		this._clientService
			.postRequest('fetchhealthscore', {
				UUID: getAuthUUID(),
			})
			.subscribe(data => {
				console.log('HUzzi');
				this.data = <IHealth.IBmiScore>(data ? data : {});
				console.log('HUzzi');
				console.log(this.data);

				this.renderDonut();
				console.debug(this.data);
			});
	}
	private renderDonut() {
		var colors = [
			'#053C6D',
			'#FFE3BB',
			'#D1CFBB',
			'#E77817',
			'#E46713',
			'#97281E',
		];
		var data = {
			labels: this.stressLevels,
			datasets: [
				{
					data: this.stressPoints,
					backgroundColor: colors,
					hoverBackgroundColor: colors,
				},
			],
		};
		this.doughnutChart = new Chart('doughnutCanvas', {
			type: 'doughnut',
			data: data,
			radius: '95%',
			options: {
				tooltips: {
					callbacks: {
						title: (tooltipItem, data) => {
							return null;
						},
						label: (tooltipItem, data) => {
							return null;
						},
					},
				},
				responsive: false,
				rotation: 1 * Math.PI,
				circumference: 1 * Math.PI,
				showLine: true,
				showLabel: true,
				legend: {
					display: false,
					position: 'right',
					labels: {
						usePointStyle: true,
						padding: 10,
						fontSize: 10,
					},
				},
			},
		});
	}

	public getSelected(level: number) {
		let min = (100 / 6) * (level - 1);
		let max = (100 / 6) * level;
		return max >= this.data.Stress && this.data.Stress > min;
	}

	public getStressLevel() {
		if (!this.stressIndex) return;
		return this.stressLevels[this.stressIndex];
	}

	public getArrowTransformClassName() {
		const stress = this.data.Stress;
		let className = 'pin-1';
		let transform = -90;
		this.stressIndex = 0;
		if (stress > 16.6 && stress < 33.2) {
			transform = -60;
			this.stressIndex = 1;
			className = 'pin-2';
		} else if (stress >= 33.2 && stress < 49.8) {
			transform = -30;
			this.stressIndex = 2;
			className = 'pin-3';
		} else if (stress >= 49.8 && stress < 66.4) {
			transform = 0;
			this.stressIndex = 3;
			className = 'pin-4';
		} else if (stress >= 66.4 && stress < 83) {
			transform = 30;
			this.stressIndex = 4;
			className = 'pin-5';
		} else if (stress >= 83 && stress < 100) {
			transform = 60;
			this.stressIndex = 5;
			className = 'pin-6';
		}
		return `pin ${className}`;
	}

	public getArrowTransformStyle() {
		const stress = this.data.Stress;
		let transform;
		if (stress <= 16.6) {
			transform = -90;
			this.stressIndex = 0;
		} else if (stress > 16.6 && stress < 33.2) {
			transform = -60;
			this.stressIndex = 1;
		} else if (stress >= 33.2 && stress < 49.8) {
			transform = -30;
			this.stressIndex = 2;
		} else if (stress >= 49.8 && stress < 66.4) {
			transform = 0;
			this.stressIndex = 3;
		} else if (stress >= 66.4 && stress < 83) {
			transform = 30;
			this.stressIndex = 4;
		} else if (stress >= 83 && stress < 100) {
			transform = 60;
			this.stressIndex = 5;
		}
		return `rotate(${transform}deg)`;
	}
	public getBMIRangeConfig() {
		const bmi = this.data.BMI;
		const positionFactor = 64 / 2; // element width / 2 as the position should be in center
		if (bmi < 18.5) {
			return { position: -6, label: 'Underweight' };
		} else if (bmi >= 18.5 && bmi <= 23) {
			return { position: 58, label: 'Healthy' };
		} else if (bmi > 23 && bmi <= 25) {
			return { position: 120, label: 'Slightly Over Weight' };
		} else if (bmi > 25 && bmi <= 27.5) {
			return { position: 190, label: 'Overweight' };
		} else if (bmi > 27.5) {
			return { position: 249, label: 'Obese' };
		}
	}
}
