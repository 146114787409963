import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {Location} from '@angular/common';
import {FormGroup, FormControl} from '@angular/forms';
import {getCorporateId, getUserId} from 'src/app/shared/util/auth-utils';
import {Environment} from 'src/environments/environment';
@Component({
	selector: 'app-playstation-team-edit',
	templateUrl: './playstation-team-edit.component.html',
	styleUrls: ['./playstation-team-edit.component.scss'],
})
export class PlaystationTeamEditComponent implements OnInit {
	public data: any = {};
	public formData: FormGroup;
	public groupId;
	public type = 1;
	@ViewChild('fileInput') fileInput;
	public isLoading = false;
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {
		this._route.params.subscribe(params => {
			console.log('Recieved paramters: ', params);
			if (params) {
				this.groupId = params.groupId;
				this.loasSelectedGroupData(params.groupId);
			}
		});
	}
	public changeType($event) {
		this.type = parseInt($event.target.value);
	}
	ngOnInit() {
		this.formData = new FormGroup({
			name: new FormControl(),
			icon: new FormControl(),
		});
	}
	public getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}
	public createTeam() {
		if (!this.formData.value.name) {
			this._appUtils.showSnackBar({
				message: 'Name is required to edit a team',
				type: 'danger',
			});
			return;
		}
		let fi = this.fileInput.nativeElement;
		if (fi.files && fi.files[0]) {
			if (this._appUtils.checkFileName(fi.value)) {
				let fileToUpload = fi.files[0];
				this.getBase64(fileToUpload).then(data => {
					this.postCreateTeam(data);
				});
			}
		} else {
			this.postCreateTeam('');
		}
	}
	public postCreateTeam(bs4) {
		let postData = {
			Id: this.groupId,
			CorporateId: getCorporateId(),
			GroupName: this.formData.value.name,
			Picturbase64string: bs4,
			GroupAdminId: getUserId(),
			IsPublc: this.type ? true : false,
			CreatedBy: getUserId(),
		};
		this.isLoading = true;
		this._clientService
			.putChallengeRequest('api/Groups/UpdateGroup', postData)
			.subscribe(
				res => {
					this.isLoading = false;
					if (res) {
						this._appUtils.showSnackBar({
							message: 'Team updated successfully',
							type: 'success',
						});
						this._router.navigate([
							'/challenge/playstation/team/add-member/',
							{groupId: res},
						]);
					}
				},
				() => {
					this.isLoading = false;
				},
			);
	}
	public imageUrl(): string {
		if (this.data) {
		let p = this.data['ImagePath'];
		return Environment.groupImagechallengeUrl + p;
		}
	
	}

	backClicked() {
		this._location.back();
	}
	private loasSelectedGroupData(id) {
		this._clientService
			.getChallengeRequest('api/Groups?GroupId=' + id)
			.subscribe(res => {
				debugger
				console.log(res);
				this.data = res;
				if (this.data) {
					this.formData.controls.name.setValue( this.data['GroupName']);
					// this.formData.controls.icon.setValue( Environment.groupImagechallengeUrl + p);
				}
			});
	}
}
