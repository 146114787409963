import {Injectable} from '@angular/core';
import {HealthEnums} from './enums';

import * as moment from 'moment';
import {ClientService} from '../http/client.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {getAuthUUID} from './auth-utils';

@Injectable({
	providedIn: 'root',
})
export class HealthPrintUtilService {
	constructor(private _clientService: ClientService) {}

	public;
	createPostData(tab: HealthEnums.HealthPrint): object {
		const momentParam = 'month';
		const currentStartDate = moment()
			// .subtract(2, 'months') // TODO Remove
			.startOf(momentParam)
			.format('DD-MM-YYYY');
		const currentEndDate = moment()
			// .subtract(2, 'months')// TODO Remove
			.endOf(momentParam)
			.format('DD-MM-YYYY');
		const prevStartDate = moment()
			.subtract(1, 'months') // TODO - replace 1 with 3
			.startOf(momentParam)
			.format('DD-MM-YYYY');
		const prevEndDate = moment()
			.subtract(1, 'months') // TODO - replace 1 with 3
			.endOf(momentParam)
			.format('DD-MM-YYYY');
		const commonData = {
			UUID: getAuthUUID(),
			tab: tab.toUpperCase(),
		};

		return {
			...commonData,
			current: {
				dateRange: {
					startDate: currentStartDate,
					endDate: currentEndDate,
				},
				dataPoints: [],
			},
			previous: {
				dateRange: {
					startDate: prevStartDate,
					endDate: prevEndDate,
				},
				dataPoints: [],
			},
		};
	}

	public massageData(response: any) {
		const {current, previous} = response;
		return [
			{
				endDate: current.dateRange.endDate,
				startDate: current.dateRange.startDate,
				dataPoints: current.dataPoints,
			},
			{
				endDate: previous.dateRange.endDate,
				startDate: previous.dateRange.startDate,
				dataPoints: previous.dataPoints,
			},
		];
	}

	public getdata(tabName: HealthEnums.HealthPrint): Observable<any> {
		let data = this.createPostData(tabName);
		return this._clientService
			.postRequest(HealthEnums.PageType.Trend, data)
			.pipe(
				map(response => {
					return this.massageData(response);
				}),
			);
	}
}
