import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-badge',
	templateUrl: './badge.component.html',
	styleUrls: ['./badge.component.sass'],
})
export class BadgeComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
