import { Component, OnInit, ViewChild } from '@angular/core';
import { IHealth } from 'src/app/shared/interface/health';
import { Environment } from 'src/environments/environment';
import { ClientService } from 'src/app/shared/http/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtilsService } from 'src/app/shared/util/app-utils.service';
import { getCorporateId, getUserId } from 'src/app/shared/util/auth-utils';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
	selector: 'app-playstation-team-create',
	templateUrl: './playstation-team-create.component.html',
	styleUrls: ['./playstation-team-create.component.scss'],
})
export class PlaystationTeamCreateComponent implements OnInit {
	public imageUrl = Environment.groupImagechallengeUrl;
	public formData: FormGroup;
	public isLoading = false;

	public type = 1;
	@ViewChild('fileInput') fileInput;

	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService) { }

	ngOnInit() {
		this.formData = new FormGroup({
			name: new FormControl('',Validators.compose([Validators.required,Validators.pattern('^[A-Za-z@ .-]+$')])),
			icon: new FormControl()
		});
	}

	public getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}
	public changeType($event) {
		this.type = parseInt($event.target.value);
	}
	public createTeam() {
		debugger
		if (this.formData.controls.name.hasError('required')) {
			this._appUtils.showSnackBar({
				message: 'Name is required to create a team',
				type: 'danger',
			});
			return;
		}

		// if (this.formData.controls.name.hasError('pattern')) {
		// 	this._appUtils.showSnackBar({
		// 		message: 'Only (@ . -) Special charcters are allowed.',
		// 		type: 'danger',
		// 	});
		// 	return;
		// }

		let fi = this.fileInput.nativeElement;
		if (fi.files && fi.files[0]) {
			if (this._appUtils.checkFileName(fi.value)) {
				let fileToUpload = fi.files[0];
				this.getBase64(fileToUpload).then(data => {
					this.postCreateTeam(data);
				});
			}
		} else {
			this.postCreateTeam('');
		}
	}
	public postCreateTeam(bs4) {
		this.isLoading = true;
		let postData = {
			CorporateId: getCorporateId(),
			GroupName: this.formData.value.name,
			Picturbase64string: bs4,
			GroupAdminId: getUserId(),
			IsPublc: this.type ? true : false,
			CreatedBy: getUserId(),
		};
		this._clientService.postChallengeRequest('api/Groups', postData).subscribe(
			res => {
				if (res) {
					this._appUtils.showSnackBar({
						message: 'Team created successfully',
						type: 'success',
					});
					this._router.navigate([
						'/challenge/playstation/team/add-member/',
						{ groupId: res },
					]);
				}
				this.isLoading = false;
			},
			() => {
				this.isLoading = false;
			},
		);
	}
}
