import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompeteComponent} from './compete/compete.component';
import {ChallengeComponent} from './challenge/challenge.component';
import {PlaystationHomeComponent} from './playstation-home/playstation-home.component';
import {PlaystationRoutingModule} from './playstation-routing.module';

@NgModule({
 declarations: [
  CompeteComponent,
  ChallengeComponent,
  PlaystationHomeComponent,
 ],
 imports: [CommonModule, PlaystationRoutingModule],
})
export class PlaystationModule {}
