import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IHealth} from '../shared/interface/health';
import {GraphMessaging} from '../shared/util/constants';
import {RewardContainerComponent} from '../reward/reward-container/reward-container.component';
import {AnalysisHomeComponent} from '../analysis/analysis-home/analysis-home.component';
import {AnalysisDetailComponent} from '../analysis/analysis-detail/analysis-detail.component';
import {GoalListComponent} from '../goal/goal-list/goal-list.component';
import {ChallengeHomeComponent} from './challenge-home/challenge-home.component';
import {ChallengeRequestsComponent} from './challenge-requests/challenge-requests.component';
import {ChallengeAddnewComponent} from './challenge-addnew/challenge-addnew.component';
import {ChallengeActiveComponent} from './challenge-active/challenge-active.component';
import {ChallengeSearchComponent} from './challenge-search/challenge-search.component';
import {FitboardIndividualComponent} from './fitboard-individual/fitboard-individual.component';
import {FitboardTeamComponent} from './fitboard-team/fitboard-team.component';
import {PlaystationIndividualComponent} from './playstation-individual/playstation-individual.component';
import {PlaystationTeamComponent} from './playstation-team/playstation-team.component';
import {PlaystationTeamViewComponent} from './playstation-team-view/playstation-team-view.component';
import {PlaystationTeamEditComponent} from './playstation-team-edit/playstation-team-edit.component';
import {PlaystationTeamViewActiveInactiveComponent} from './playstation-team-view-active-inactive/playstation-team-view-active-inactive.component';
import {PlaystationTeamSelectedComponent} from './playstation-team-selected/playstation-team-selected.component';
import {PlaystationTeamJoinComponent} from './playstation-team-join/playstation-team-join.component';
import {PlaystationTeamCreateComponent} from './playstation-team-create/playstation-team-create.component';
import {PlaystationTeamChallangeSearchComponent} from './playstation-team-challange-search/playstation-team-challange-search.component';
import {PlaystationTeamChallangeRequestComponent} from './playstation-team-challange-request/playstation-team-challange-request.component';
import {PlaystationTeamChallangeAddComponent} from './playstation-team-challange-add/playstation-team-challange-add.component';
import {PlaystationTeamChallangeAdd2Component} from './playstation-team-challange-add2/playstation-team-challange-add2.component';
import {PlaystationTeamAddMemberComponent} from './playstation-team-add-member/playstation-team-add-member.component';
import {PlaystationTeamJoinRequestComponent} from './playstation-team-join-request/playstation-team-join-request.component';
import {PlaystationCompanyHomeComponent} from './playstation-company-home/playstation-company-home.component';
import {FitboardCompanyHomeComponent} from './fitboard-company-home/fitboard-company-home.component';
import {TeamChallengeActiveComponent} from './team-challenge-active/team-challenge-active.component';

const routes: Routes = [
 {
  path: '',
  redirectTo: '/challenge',
  pathMatch: 'full',
 },
 {
  path: 'challenge',
  component: ChallengeHomeComponent,
  children: [
   {
    path: 'fitboard',
    children: [
     {
      path: 'individual',
      component: FitboardIndividualComponent,
     },
     {
      path: 'team',
      component: FitboardTeamComponent,
     },
     {
      path: 'company-wise',
      component: FitboardCompanyHomeComponent,
     },
     {
      path: 'individual/challenges',
      component: ChallengeActiveComponent,
     },
     {
      path: 'individual/challenges/:id',
      component: ChallengeActiveComponent,
     },
     {
      path: 'team/challenges',
      component: TeamChallengeActiveComponent,
     },
     {
      path: 'team/challenges/:id',
      component: TeamChallengeActiveComponent,
     },
    ],
   },
   {
    path: 'playstation',
    children: [
     {
      path: 'individual',
      component: PlaystationIndividualComponent,
     },
     {
      path: 'team',
      component: PlaystationTeamComponent,
     },
     {
      path: 'team/view',
      component: PlaystationTeamViewComponent,
      children: [
       {
        path: 'active',
        component: PlaystationTeamViewActiveInactiveComponent,
       },
       {
        path: 'inactive',
        component: PlaystationTeamViewActiveInactiveComponent,
       },
       {
        path: 'active/:type',
        component: PlaystationTeamViewActiveInactiveComponent,
       },
       {
        path: 'inactive/:type',
        component: PlaystationTeamViewActiveInactiveComponent,
       },
      ],
     },
     {
      path: 'team/edit',
      component: PlaystationTeamEditComponent,
     },
     {
      path: 'team/edit/:data',
      component: PlaystationTeamEditComponent,
     },
     {
      path: 'team/search-my-team',
      component: PlaystationTeamChallangeSearchComponent,
     },
     {
      path: 'team/search-opponent-team',
      component: PlaystationTeamChallangeSearchComponent,
     },
     {
      path: 'team/search-opponent-team/:challengerId',
      component: PlaystationTeamChallangeSearchComponent,
     },
     {
      path: 'team/add',
      component: PlaystationTeamChallangeAddComponent,
     },
     {
      path: 'team/add2',
      component: PlaystationTeamChallangeAdd2Component,
     },
     {
      path: 'team/join/request',
      component: PlaystationTeamJoinRequestComponent,
     },
     {
      path: 'team/challenge/request',
      component: PlaystationTeamChallangeRequestComponent,
     },
     {
      path: 'team/join',
      component: PlaystationTeamJoinComponent,
     },
     {
      path: 'team/create',
      component: PlaystationTeamCreateComponent,
     },
     {
      path: 'team/add-member/:id',
      component: PlaystationTeamAddMemberComponent,
     },
     {
      path: 'team/add-member',
      component: PlaystationTeamAddMemberComponent,
     },
     {
      path: 'team/selected/:id',
      component: PlaystationTeamSelectedComponent,
     },
     {
      path: 'team/selected',
      component: PlaystationTeamSelectedComponent,
     },
     {
      path: 'company-wise',
      component: PlaystationCompanyHomeComponent,
     },
     {
      path: 'individual/request',
      component: ChallengeRequestsComponent,
     },
     {
      path: 'individual/request/:id',
      component: ChallengeRequestsComponent,
     },
     {
      path: 'individual/search',
      component: ChallengeSearchComponent,
     },
     {
      path: 'individual/search/:id',
      component: ChallengeSearchComponent,
     },
     {
      path: 'individual/addnew',
      component: ChallengeAddnewComponent,
     },
     {
      path: 'individual/addnew/:id',
      component: ChallengeAddnewComponent,
     },
    ],
   },
  ],
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class ChallengeRoutingModule {}
