import {Component, OnInit} from '@angular/core';

@Component({
 selector: 'app-playstation-company-home',
 templateUrl: './playstation-company-home.component.html',
 styleUrls: ['./playstation-company-home.component.scss'],
})
export class PlaystationCompanyHomeComponent implements OnInit {
 constructor() {}

 ngOnInit() {}
}
