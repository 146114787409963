import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfileRoutingModule} from './profile-routing.module';
import {ProfileHomeComponent} from './profile-home/profile-home.component';
import {RewardModule} from '../reward/reward.module';
import {ProfileDataComponent} from './profile-data/profile-data.component';
import {AnalysisModule} from '../analysis/analysis.module';

@NgModule({
 declarations: [ProfileHomeComponent, ProfileDataComponent],
 imports: [CommonModule, ProfileRoutingModule, RewardModule, AnalysisModule],
})
export class ProfileModule {}
