import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrendHomeComponent} from './trend-home/trend-home.component';
import {TrendChartComponent} from './trend-chart/trend-chart.component';
import {TrendRoutingModule} from './trend-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {ChartMessagingModule} from '../chart-messaging/chart-messaging.module';

@NgModule({
 declarations: [TrendHomeComponent, TrendChartComponent],
 imports: [
  HttpClientModule,
  BrowserModule,
  CommonModule,
  TrendRoutingModule,
  ChartMessagingModule,
 ],
})
export class TrendModule {}
