// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
};
export const Environment = {
  production: false,  
  //  baseUrl: `http://localhost:53437//api/`,
  // baseUrl: `https://ilapi.watchyourhealth.com/api/`,
  baseUrl: `https://testilapi.watchyourhealth.com/api/`,
  challengeUrl: 'https://competition.watchyourhealth.com/',
  groupImagechallengeUrl: 'https://competition.watchyourhealth.com/images/GroupIcons/',

  individualImagechallengeUrl: 'http://testabsl.watchyourhealth.com/Images/profile/',
  HealthPrintUrl: 'https://icicighi.page.link/healthPrintUAT',
  // HealthPrintUrl:'https://icicighi.page.link/healthPrintProd',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
