import {Injectable} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {IHealth} from '../interface/health';

@Injectable({
	providedIn: 'root',
})
export class AppUtilsService {
	public onShowSnackBar = new Subject();
	public onAlertShow = new Subject();
	constructor() {}
	/**
	 * on show snackbar
	 * @param message show snackbar message
	 */
	public showSnackBar(message: IHealth.ISnackBar): void {
		this.onShowSnackBar.next(message);
	}
	/**
	 * on show snackbar
	 * @param message show snackbar message
	 */
	public showAlert(message: IHealth.IAlert): void {
		this.onAlertShow.next(message);
	}
	public IsJsonString(str) {
		try {
			var json = JSON.parse(str);
			return typeof json === 'object';
		} catch (e) {
			return false;
		}
	}
	public checkFileName(fileName) {
		if (fileName == '') {
			return false;
		} else if (
			fileName.split('.')[1].toUpperCase() == 'PNG' ||
			fileName.split('.')[1].toUpperCase() == 'JPEG' ||
			fileName.split('.')[1].toUpperCase() == 'JPG'
		)
			return true;
		else {
			this.showSnackBar({
				message:
					'File with ' +
					fileName.split('.')[1] +
					' is invalid. Upload a validfile with png or jpg extensions',
				type: 'danger',
			});
			return false;
		}
		return true;
	}

	public nameValidator(e) {}
}
