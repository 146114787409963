import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { IHealth } from 'src/app/shared/interface/health';
import {
	FormGroup,
	FormControl,
	Validators,
	ValidationErrors,
} from '@angular/forms';
import { ClientService } from 'src/app/shared/http/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtilsService } from 'src/app/shared/util/app-utils.service';
import { forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { getAuthUUID } from 'src/app/shared/util/auth-utils';

@Component({
	selector: 'app-report-add',
	templateUrl: './report-add.component.html',
	styleUrls: ['./report-add.component.scss'],
})
export class ReportAddComponent implements OnInit {
	@ViewChild('fileInput') fileInput;
	private reportObjet: IHealth.IHealthRecord = <IHealth.IHealthRecord>{};
	private copyObject: IHealth.IHealthRecord = <IHealth.IHealthRecord>{};
	public clientFamilyList: IHealth.IClientFamilyList[];
	public healthRecordType: IHealth.IHealthRecordType[];
	public isLoading: boolean = false;
	public reportForm: FormGroup;
	public currentReportId: number;
	public isEditMode = false;
	public filesList = [];
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		// private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) { }

	ngOnInit() {
		this._route.queryParams.subscribe(params => {
			console.debug(params);
			console.debug(this._route.snapshot.params.id);
			const editID = this._route.snapshot.params.id;

			if (editID) {
				this.isLoading = true;
				this._clientService
					.postRequest(`fetchHealthRecordByID`, { ID: editID })
					.subscribe(response => {
						this.copyObject = { ...response };
						this.currentReportId = this.copyObject.ID;
						this.isEditMode = true;
						this.filesList = this.copyObject.HealthRecordFilesList;
						this.isLoading = false;
					});
			}

			this.loadDropDownData();
		});
		document.body.classList.remove('ehr');
	}

	private loadDropDownData() {
		this.isLoading = true;

		forkJoin(
			this._clientService.postRequest('fetchFamilyMember', {
				CreatedBy: getAuthUUID(),
			}),
			this._clientService.postRequest('FetchHealthRecordTypes', null),
		).subscribe(response => {
			this.isLoading = false;
			this.clientFamilyList = [
				{ Name: 'Self', FID: 0 },
				...response[0].FamilyMemberList,
			];
			this.healthRecordType = [...response[1], { ID: 12, Name: 'Insurance' }];
			this.createForm();
		});
	}
	// private IsJsonString(str) {
	//   try {
	//     var json = JSON.parse(str);
	//     return typeof json === 'object';
	//   } catch (e) {
	//     return false;
	//   }
	// }
	private createForm() {
		this.reportForm = new FormGroup({
			reportName: new FormControl(this.copyObject.Subject, [
				Validators.required,
			]),
			reason: new FormControl(this.copyObject.Reason),
			testName: new FormControl(this.copyObject.TestName),
			labName: new FormControl(this.copyObject.LabName),
			noOfDays: new FormControl(this.copyObject.NoOfDays),
			clientFamilyDetailID: new FormControl(
				this.copyObject.ClientFamilyDetailID || 0,
			),
			healthRecordTypeID: new FormControl(
				this.copyObject.HealthRecordTypeID || 1,
			),
			file: new FormControl(),
			date: new FormControl(
				moment(this.copyObject.ReportDate).format('YYYY-MM-DD'),
				[Validators.required],
			),
			doctor: new FormControl(this.copyObject.DoctorReference),
			remarks: new FormControl(this.copyObject.Remarks),
			reference: new FormControl(this.copyObject.ReferenceType),
			referenceRadio: new FormControl(this.copyObject.ReferenceType || 'Self'),
			//Insurance
			companyName: new FormControl(this.copyObject.CompanyName),
			insuranceType: new FormControl(this.copyObject.InsuranceType),
			sumAssured: new FormControl(this.copyObject.SumInsured),
			premium: new FormControl(this.copyObject.Premium),
			policyStartDate: new FormControl(
				this.copyObject.PolicyDate || moment().format('YYYY-MM-DD'),
			),
			policyEndDate: new FormControl(
				this.copyObject.ExpireDate || moment().format('YYYY-MM-DD'),
			),
		});
	}

	public submitForm() {
		this.isLoading = true;
		const exitingRecord = this.copyObject.HealthRecordFilesList;
		let postData = this.createPostdata();
		postData.HealthRecordFilesList = [
			...(exitingRecord && exitingRecord.length ? exitingRecord : []),
		];

		let fi = this.fileInput.nativeElement;
		if (fi.files && fi.files.length) {
			const filesToUpload = Array.from(fi.files);

			filesToUpload.forEach((file, index) => {
				var form = new FormData();

				form.append('EHRupload', file as any);
				this._clientService
					.uploadFile('UploadFiles', form)
					.subscribe(response => {
						postData.HealthRecordFilesList = [
							...postData.HealthRecordFilesList,
							...response,
						];
						// Nasty hack to check if allfiles are uploaded
						index + 1 === filesToUpload.length &&
							this.checkPostPatchReport(postData);
					});
			});
		} else {
			this.checkPostPatchReport(postData);
		}
	}

	private checkPostPatchReport(postData: IHealth.IHealthRecord) {
		if (this.isEditMode) {
			this.postReportData('UpdateHealthRecord', postData);
		} else {
			this.postReportData('AddHealthRecord', postData);
		}
	}

	private postReportData(url: string, postData: IHealth.IHealthRecord) {
		this._clientService
			.postRequest(url, postData)
			.subscribe((result: IHealth.HttpResult) => {
				if (result.StatusCode == 200) {
					this._appUtils.showSnackBar(<IHealth.ISnackBar>{
						message: result.Msg,
					});
					this.isLoading = false;
					this._location.back();
				}
			});
	}
	// private changeQuery() {
	//   //this._router.navigate(['.'], { relativeTo: this._route, queryParams: {params:JSON.stringify(data)},replaceUrl: true });
	//   this._router.navigate(['/ehr/family-member-list'], { replaceUrl: true });
	// }
	private createPostdata() {
		let postData: IHealth.IHealthRecord = <IHealth.IHealthRecord>{};
		postData.UUID = getAuthUUID();
		postData.ID = this.copyObject.ID ? this.copyObject.ID : 0;
		postData.ClientFamilyDetailID = this.reportForm.value.clientFamilyDetailID;
		postData.HealthRecordTypeID = this.reportForm.value.healthRecordTypeID;
		postData.ReportDate = moment(this.reportForm.value.date).format(
			'YYYY-MM-DD',
		);
		postData.Subject = this.reportForm.value.reportName;
		postData.Reason = this.reportForm.value.reason;
		postData.Remarks = this.reportForm.value.remarks;
		if (this.reportForm.value.referenceRadio == 'Doctor') {
			postData.ReferenceType = 'Doctor';
			postData.DoctorReference = this.reportForm.value.doctor;
		} else {
			postData.ReferenceType = 'Self';
			postData.DoctorReference = 'No Doc';
		}

		postData.CompanyName = this.reportForm.value.companyName;
		postData.InsuranceType = this.reportForm.value.insuranceType;
		postData.SumInsured = this.reportForm.value.sumAssured;
		postData.Premium = this.reportForm.value.premium;

		postData.PolicyDate = this.getProperDate(
			this.reportForm.value.policyStartDate,
		);
		postData.ExpireDate = this.getProperDate(
			this.reportForm.value.policyEndDate,
		);

		postData.LabName = this.reportForm.value.labName;
		postData.TestName = this.reportForm.value.testName;
		postData.NoOfDays = this.reportForm.value.noOfDays;
		postData.CreatedBy = getAuthUUID();
		return postData;
	}

	getProperDate(date) {
		if (date) {
			if (moment(date).diff(moment('1880-01-01').format('YYYY-MM-DD')) < 0) {
				return moment().format('YYYY-MM-DD');
			} else {
				return moment(date).format('YYYY-MM-DD');
			}
		} else {
			return moment(date).format('YYYY-MM-DD');
		}
	}

	public get isFormValid(): boolean {
		if (this.reportForm.invalid) {
			return false;
		} else if (this.reportObjet.ID) {
			if (this.copyObject.Subject != this.reportForm.value.reportName)
				return true;
			else if (this.copyObject.Reason != this.reportForm.value.reason)
				return true;
			else if (
				moment(this.copyObject.ReportDate).format('YYYY-MM-DD') !=
				moment(this.reportForm.value.date).format('YYYY-MM-DD')
			)
				return true;
			else if (this.copyObject.TestName != this.reportForm.value.testName)
				return true;
			else if (this.copyObject.LabName != this.reportForm.value.labName)
				return true;
			else if (this.copyObject.NoOfDays != this.reportForm.value.noOfDays)
				return true;
			else if (this.copyObject.Remarks != this.reportForm.value.remarks)
				return true;
			else if (this.copyObject.DoctorReference != this.reportForm.value.doctor)
				return true;
			else if (
				this.copyObject.ClientFamilyDetailID !=
				this.reportForm.value.clientFamilyDetailID
			)
				return true;
			else if (
				this.copyObject.HealthRecordTypeID !=
				this.reportForm.value.healthRecordTypeID
			)
				return true;
			else {
				return false;
			}
		} else {
			return true;
		}
	}

	public get headerLable(): string {
		if (this.isEditMode) {
			return 'Update Report';
		} else {
			return 'Add New Report';
		}
	}

	public get showReportSubjet(): boolean {
		return true;
	}
	public get showAttachDocument(): boolean {
		return true;
	}
	public get showDoctorReference(): boolean {
		if (
			this.reportForm.value.healthRecordTypeID == 9 ||
			this.reportForm.value.healthRecordTypeID == 12
		) {
			return false;
		} else {
			return true;
		}
	}
	public get showReason(): boolean {
		if (
			this.reportForm.value.healthRecordTypeID == 9 ||
			this.reportForm.value.healthRecordTypeID == 12
		) {
			return false;
		} else {
			return true;
		}
	}
	public get showLabName(): boolean {
		if (this.reportForm.value.healthRecordTypeID == 4) {
			return true;
		} else {
			return false;
		}
	}
	public get showTestName(): boolean {
		if (this.reportForm.value.healthRecordTypeID == 4) {
			return true;
		} else {
			return false;
		}
	}
	public get showNumberOfDays(): boolean {
		if (this.reportForm.value.healthRecordTypeID == 4) {
			return true;
		} else {
			return false;
		}
	}
	public get showCompanyName(): boolean {
		if (this.reportForm.value.healthRecordTypeID == 12) {
			return true;
		} else {
			return false;
		}
	}
	public get showInsuranceField(): boolean {
		if (this.reportForm.value.healthRecordTypeID == 12) {
			return true;
		} else {
			return false;
		}
	}
	public get showReportDate(): boolean {
		return true;
	}
	public get showFamilyMember(): boolean {
		return true;
	}

	public get isFutureDate(): boolean {
		let bool =
			moment(this.reportForm.value.date).diff(new Date(), 'days', true) > 0;
		if (bool) {
			this.reportForm.setErrors({ invalid: true });
			return bool;
		} else {
			this.reportForm.setErrors(null);
			return bool;
		}
	}

	public deleteFile(fileId) {
		this._clientService
			.postRequest('DeleteFilesToDBRepo', { ID: fileId })
			.subscribe(response => {
				console.debug(response);
				this._appUtils.showSnackBar(<IHealth.ISnackBar>{
					message: response.Msg,
				});
				window.location.reload();
			});
	}
}
