import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-ehr-home',
	templateUrl: './ehr-home.component.html',
	styleUrls: ['./ehr-home.component.sass'],
})
export class EhrHomeComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
