import {IHealth} from '../interface/health';

const messaging = {
	sleep: {
		positive: {
			title: 'Great going!',
			desc: "You're getting regular with sleep pattern, <br/> keep it up.",
		},
		nagative: {
			title: 'Improvement required',
			desc:
				"You've been sleeping less lately, <br/> Try to achieve your daily targets.",
		},
	},
	steps: {
		positive: {
			title: 'Great going!',
			desc: "You're getting regular with steps, <br/> keep it up.",
		},
		nagative: {
			title: "You need some improvement on the steps front!",
			desc: 'Walk everyday for a healthier lifestyle.',
		},
	},
	water: {
		positive: {
			title: "You're going great!",
			desc: "Keep yourself hydrated, just the way you're doing",
		},
		nagative: {
			title: 'Improvement required',
			desc: 'Water is essential for body, stay hydrated.',
		},
	},
	meditation: {
		positive: {
			title: "You're going great!",
			desc: 'Meditation reduces stress and enhances self-awareness',
		},
		nagative: {
			title: 'Meditate regularly',
			desc: 'It reduces stress and enhances self-awareness',
		},
	},
	sit: {
		positive: {
			title: 'Great Going!',
			desc: 'You seem to be hitting all the targets right.',
		},
		nagative: {
			title: 'Improvement required',
			desc: 'Being seated for long duration increases the possibility of obesity and associated ailments.',
		},
	},
	stand: {
		positive: {
			title: 'Great Going!',
			desc: 'You seem to be hitting all the targets right.',
		},
		nagative: {
			title: 'Improvements required',
			desc: 'Standing reduces your risk of weight gain and obesity.',
		},
	},
	activehours: {
		positive: {
			title: 'Great Going!',
			desc: 'You seem to be hitting all the targets right.',
		},
		nagative: {
			title: 'Improvements required',
			desc: 'Standing reduces your risk of weight gain and obesity.',
		},
	},
};

export const TrendMessaging = {
	sleep: <IHealth.RouteData>{
		tabName: 'sleep',
		name: 'Minutes Sleep',
		title: "You're getting regular!",
		description: 'Try and balance a consistent number of sleep hours',
		colorPrimary: '#52912E',
		colorSecondary: '#B4C55B',
		bgColor: '#1A2416',
		unit: 'Minutes',
		messaging: messaging.sleep,
	},
	steps: <IHealth.RouteData>{
		tabName: 'steps',
		name: 'Steps Walked',
		title: "You're missing few days!",
		description: 'Walk everyday for a healthier lifestyle.',
		colorPrimary: '#8A56AC',
		colorSecondary: '#053C6D',
		bgColor: '#352641',
		unit: 'steps',
	},
	water: <IHealth.RouteData>{
		tabName: 'water',
		name: 'Glasses of Water',
		title: 'Water is essential!',
		description: 'Avoid feeling dehydrated.',
		colorPrimary: '#4666E5',
		colorSecondary: '#4EBDEF',
		bgColor: '#132641',
		unit: 'Glasses',
	},
	calories: <IHealth.RouteData>{
		tabName: 'calories',
		name: 'Calories',
		title: 'Good going!',
		description: 'You have improved quite a bit.',
		colorPrimary: '#A63C3C',
		colorSecondary: '#F75969',
		bgColor: '#291D1D',
		unit: 'Kcal',
	},
	meditation: <IHealth.RouteData>{
		tabName: 'meditation',
		name: 'Times Meditated',
		title: 'Good going!',
		description: 'You have improved quite a bit.',
		colorPrimary: '#FF5A1D',
		colorSecondary: '#FFA430',
		bgColor: '#291B16',
		unit: 'minutes',
	},
	commute: <IHealth.RouteData>{
		tabName: 'commute',
		name: 'Minutes in Commute',
		title: 'Good going!',
		description: 'You have improved quite a bit.',
		colorPrimary: '#00BFA5',
		colorSecondary: '#1DE9B6',
		bgColor: '#112523',
		unit: 'minutes',
	},
	stand: <IHealth.RouteData>{
		tabName: 'stand',
		name: 'Stand Hours',
		title: 'Good going!',
		description: 'Take some rest.',
		colorPrimary: '#8A56AC',
		colorSecondary: '#053C6D',
		bgColor: '#352641',
		unit: 'minutes',
	},
	sit: <IHealth.RouteData>{
		tabName: 'sit',
		name: 'Minutes Sat',
		title: 'Good going!',
		description:
			'Taking a small walk every hour not only improves your focus but also gives you a fresh persepective.',
		colorPrimary: '#FF5A1D',
		colorSecondary: '#FFA430',
		bgColor: '#291B16',
		unit: 'minutes',
	},
	activehours: <IHealth.RouteData>{
		tabName: 'activehours',
		name: 'activehours',
		title: 'Good going activehours!',
		description: 'You have improved quite a bit activehours.',
		colorPrimary: '#00BFA5',
		colorSecondary: '#1DE9B6',
		bgColor: '#112523',
		unit: 'minutes activehours',
	},
};

export const GraphMessaging = {
	sleep: <IHealth.RouteData>{
		tabName: 'sleep',
		name: 'Minutes Slept',
		colorPrimary: '#E77817',
		colorSecondary: '#97291E',
		textColor: '#00C0F3',
		bgColor: '#053C6D',
		messaging: messaging.sleep,
	},
	steps: <IHealth.RouteData>{
		tabName: 'steps',
		name: 'Steps',
		colorPrimary: '#E77817',
		colorSecondary: '#97291E',
		bgColor: '#053C6D',
		textColor: '#00C0F3',
		messaging: messaging.steps,
	},
	water: <IHealth.RouteData>{
		tabName: 'water',
		name: 'Glasses of Water',
		colorPrimary: '#E77817',
		colorSecondary: '#97291E',
		bgColor: '#053C6D',
		textColor: '#00C0F3',
		messaging: messaging.water,
	},
	meditation: <IHealth.RouteData>{
		tabName: 'meditation',
		name: 'Times Meditated',
		colorPrimary: '#E77817',
		colorSecondary: '#97291E',
		bgColor: '#053C6D',
		textColor: '#00C0F3',
		messaging: messaging.meditation,
	},
	// calories: <IHealth.RouteData>{
	//   tabName: "calories",
	//   name: "Calories",
	//   colorPrimary: "#E77817",
	//   colorSecondary: "#97291E",
	//   bgColor: "#053C6D"
	// },
	// commute: <IHealth.RouteData>{
	//   tabName: "commute",
	//   name: "Minutes in Commute",
	//   colorPrimary: "#E77817",
	//   colorSecondary: "#97291E",
	//   bgColor: "#053C6D"
	// },
	stand: <IHealth.RouteData>{
		tabName: 'stand',
		name: 'Stand Hours',
		colorPrimary: '#E77817',
		colorSecondary: '#97291E',
		bgColor: '#053C6D',
		unit: 'minutes',
		textColor: '#00C0F3',
		messaging: messaging.stand,
	},
	sit: <IHealth.RouteData>{
		tabName: 'sit',
		name: 'Minutes Sat',
		colorPrimary: '#E77817',
		colorSecondary: '#97291E',
		bgColor: '#053C6D',
		unit: 'minutes',
		textColor: '#00C0F3',
		messaging: messaging.sit,
	},
	activehours: <IHealth.RouteData>{
		tabName: 'activehours',
		name: 'Active Hours',
		colorPrimary: '#E77817',
		colorSecondary: '#97291E',
		bgColor: '#053C6D',
		unit: 'hours',
		textColor: '#00C0F3',
		messaging: messaging.activehours,
	},
};
