import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
 selector: 'app-playstation-individual',
 templateUrl: './playstation-individual.component.html',
 styleUrls: ['./playstation-individual.component.scss'],
})
export class PlaystationIndividualComponent implements OnInit {
 public data = {
  text: 'Ongoing Challenges',
  url: 'OngoingByUser',
 };
 constructor(private router: Router) {}

 ngOnInit() {}
 openRequest() {
  this.router.navigate([
   '/challenge/playstation/individual/request/',
   {data: 'data'},
  ]);
 }
 openAddnew() {
     debugger;
  this.router.navigate([
   '/challenge/playstation/individual/search/',
   {data: 'data'},
  ]);
 }
}
