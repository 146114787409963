import {Component, OnInit, Input} from '@angular/core';

@Component({
	selector: 'app-chart-messaging',
	templateUrl: './chart-messaging.component.html',
	styleUrls: ['./chart-messaging.component.sass'],
})
export class ChartMessagingComponent implements OnInit {
	@Input('title') title: string;
	@Input('description') description: string;
	@Input('bgColor') bgColor: string;
	@Input('descColor') descColor: string;

	constructor() {}

	ngOnInit() {}
}
