import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-goal-detail',
	templateUrl: './goal-detail.component.html',
	styleUrls: ['./goal-detail.component.sass'],
})
export class GoalDetailComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
