import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IHealth} from '../shared/interface/health';
import {TrendMessaging} from '../shared/util/constants';
import {EhrHomeComponent} from './ehr-home/ehr-home.component';
import {FamiliyMemberAddComponent as FamilyMemberAddComponent} from './familiy-member-add/familiy-member-add.component';
import {FamiliyMemberDetailComponent as FamilyMemberDetailComponent} from './familiy-member-detail/familiy-member-detail.component';
import {FamiliyMemberListComponent as FamilyMemberListComponent} from './familiy-member-list/familiy-member-list.component';
import {ReportAddComponent} from './report-add/report-add.component';
import {ReportDetailComponent} from './report-detail/report-detail.component';
import {ReportListComponent} from './report-list/report-list.component';

const routes: Routes = [
 {
  path: '',
  redirectTo: '/ehr',
  pathMatch: 'full',
 },
 {
  path: 'ehr',
  component: EhrHomeComponent,
  children: [
   {
    path: 'family-member-add/:fid',
    component: FamilyMemberAddComponent,
   },
   {
    path: 'family-member-add',
    component: FamilyMemberAddComponent,
   },
   {
    path: 'family-member-detail',
    component: FamilyMemberDetailComponent,
   },
   {
    path: 'family-member-list',
    component: FamilyMemberListComponent,
   },
   {
    path: 'report-add',
    component: ReportAddComponent,
   },
   {
    path: 'report-add/:id',
    component: ReportAddComponent,
   },
   {
    path: 'report-detail/:id',
    component: ReportDetailComponent,
   },
   {
    path: 'report-list/:fid',
    component: ReportListComponent,
   },
   {
    path: 'report-list',
    component: ReportListComponent,
   },
  ],
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class EhrRoutingModule {}
