import {Component, OnInit, OnDestroy} from '@angular/core';
import {ClientService} from 'src/app/shared/http/client.service';
import {IHealth} from 'src/app/shared/interface/health';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {getAuthUUID} from 'src/app/shared/util/auth-utils';

import * as moment from 'moment';

@Component({
	selector: 'app-familiy-member-list',
	templateUrl: './familiy-member-list.component.html',
	styleUrls: ['./familiy-member-list.component.sass'],
})
export class FamiliyMemberListComponent implements OnInit {
	public familyList: IHealth.IFamilyMemberList[];
	public isLoading: boolean;
	public openAccordions: number[] = [];

	constructor(
		private _clientService: ClientService,
		private _apputilService: AppUtilsService,
	) {}

	ngOnInit() {
		this.loadData();
		document.body.classList.add('ehr');
	}
	OnDestroy() {
		document.body.classList.remove('ehr');
	}
	private loadData() {
		this.isLoading = true;
		this._clientService
			.postRequest('fetchFamilyMember', {
				CreatedBy: getAuthUUID(),
			})
			.subscribe((data: IHealth.IFamilyData) => {
				this.isLoading = false;
				if (data.RModel.StatusCode == 200) {
					this.familyList = data.FamilyMemberList;
					this.toggelAccordian(0);
				}
			});
	}

	public onDelete(data: IHealth.IFamilyMemberList) {
		this._apputilService.showAlert(<IHealth.IAlert>{
			message: `Are you sure, you want to delete ${data.Name}?`,
			showNegative: true,
			showPositive: true,
			positiveText: 'YES',
			negativeText: 'NO',
			positiveFunction: this.deleteFamilyMember.bind(this, data),
		});
	}

	private deleteFamilyMember(data: IHealth.IFamilyMemberList) {
		this._clientService
			.postRequest('DeleteFamilyMember', {FID: data.FID})
			.subscribe((result: IHealth.HttpResult) => {
				if (result.StatusCode == 200) {
					this._apputilService.showSnackBar(<IHealth.ISnackBar>{
						message: result.Msg,
					});
					this.familyList = this.familyList.filter(v => v.FID != data.FID);
				}
			});
	}

	public toggelAccordian(id) {
		this.openAccordions.includes(id)
			? (this.openAccordions = this.openAccordions.filter(v => v !== id))
			: this.openAccordions.push(id);
	}

	public getDateOfBirth(value) {
		return moment(value).format('DD-MM-YYYY');
	}
}
