import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IHealth} from '../shared/interface/health';
import {GraphMessaging} from '../shared/util/constants';
import {RewardContainerComponent} from '../reward/reward-container/reward-container.component';
import {AnalysisHomeComponent} from './analysis-home/analysis-home.component';

const routes: Routes = [
 {
  path: '',
  redirectTo: '/analysis',
  pathMatch: 'full',
 },
 {
  path: 'analysis',
  component: AnalysisHomeComponent,
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class AnalysisRoutingModule {}
