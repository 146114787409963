import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-scratch-card',
	templateUrl: './scratch-card.component.html',
	styleUrls: ['./scratch-card.component.sass'],
})
export class ScratchCardComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
