import {Component, OnInit} from '@angular/core';
import * as Chart from 'chart.js';
import {IHealth} from '../../shared/interface/health';
import {ChartUtilService} from '../../shared/util/chart-util.service';
import {ClientService} from '../../shared/http/client.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HealthEnums} from '../../shared/util/enums';
import {environment} from 'src/environments/environment';
@Component({
	selector: 'app-graph-chart',
	templateUrl: './graph-chart.component.html',
	styleUrls: ['./graph-chart.component.sass'],
})
export class GraphChartComponent implements OnInit {
	public chart = [];
	public frequencies = HealthEnums.Frequency;
	public selectedFrequency: HealthEnums.Frequency;
	public routeData: IHealth.RouteData;
	// public chartDataPoint: IHealth.ChartDataPoint;
	public chartDataPoint: any;
	public goal: number;
	public settingsLink: string;
	public isGoalAchieved: boolean;
	public offset = 0;
	constructor(
		private _helathUtil: ChartUtilService,
		private _client: ClientService,
		private _http: HttpClient,
		private _route: ActivatedRoute,
	) {}

	ngOnInit() {
		this.loadRouteData();
		this.getSettingsLink();
	}

	private loadRouteData(): void {
		this._route.data.subscribe(v => {
			this.routeData = <IHealth.RouteData>v;
			this.loadData(HealthEnums.Frequency.Daily);
		});
	}

	public getSettingsLink(): void {
		let link;
		switch (this.routeData.tabName) {
			case 'sleep':
				link = '/graph/sleep/?page=sleepsetting';
				break;
			case 'water':
				link = '/graph/water/?page=watersetting';
				break;
			case 'meditation':
				link = '/graph/meditation/?page=meditationsetting';
				break;
		}
		this.settingsLink = link;
	}

	public loadData(frequency: HealthEnums.Frequency, offset: number = 0): void {
		if (this.selectedFrequency != frequency || this.offset != offset) {
			this.selectedFrequency = frequency;
			console.log(this.routeData.tabName);
			this._helathUtil
				.getdata(
					this.routeData.tabName,
					frequency,
					HealthEnums.PageType.Graph,
					offset,
				)
				.subscribe((response: IHealth.ChartDataPoint) => {
					this.chartDataPoint = response;
					console.log(JSON.stringify(response));
					console.log('************************************');
					console.log(this.chartDataPoint);
					console.log('************************************');
					this.goal = response.goal;
					this.isGoalAchieved = response.goal < response.average;
					this.renderChart();
				});
		}
		this.offset = offset;
	}

	plusLoad() {
		let off = this.offset - 1;
		this.loadData(this.selectedFrequency, off);
	}
	minusLoad() {
		let off = this.offset + 1;
		this.loadData(this.selectedFrequency, off);
	}
	private renderChart(): void {
		let chartData = {
			type: 'bar',
			data: {
				labels: this.chartDataPoint.labels,
				datasets: [
					{
						label: this.chartDataPoint.legend,
						data: this.chartDataPoint.curr,
						backgroundColor: '#e77817', //this.routeData.colorPrimary,
						borderColor: '#e77817', //this.routeData.colorPrimary,
						borderWidth: 2,
						showLines: false,
					},
				],
			},
			options: {
				tooltips: {
					callbacks: {
						title: (tooltipItem, data) => {
							return `${tooltipItem[0].value} ${this.routeData.name}`;
						},
						label: (tooltipItem, data) => {
							return tooltipItem.xLabel;
						},
					},
				},
				showLine: false,
				showLabel: false,
				legend: {
					display: false,
				},
				scales: {
					xAxes: [
						{
							barPercentage: 0.5,
							barThickness: 14,
							maxBarThickness: 20,
							minBarLength: 2,
							gridLines: {
								color: 'rgba(0, 0, 0, 0)',
							},
						},
					],
					yAxes: [
						{
							scaleLabel: {
								display: true,
								labelString: this.routeData.name,
							},
							ticks: {
								beginAtZero: true,
							},
							//   gridLines: {
							//     color: "rgba(0, 0, 0, 0)"
							// }
						},
					],
				},
			},
		};
		this.chart = new Chart('canvas', chartData);
	}

	public get percentage(): string {
		if (this.chartDataPoint) {
			let percentage: number =
				(Math.round(this.chartDataPoint.average) / this.goal) * 100; // + "%";
			percentage = Math.min(percentage, 100);
			return `${percentage}%`;
		}
	}

	public get messaging(): IHealth.Messaging {
		const messaging = this.routeData.messaging;
		return this.isGoalAchieved ? messaging.positive : messaging.nagative;
	}
}
