import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GoalListComponent} from './goal-list/goal-list.component';
import {GoalGridComponent} from './goal-grid/goal-grid.component';
import {GoalDetailComponent} from './goal-detail/goal-detail.component';
import {GoalHomeComponent} from './goal-home/goal-home.component';
import {GoalRoutingModule} from './goal-routing.module';

@NgModule({
 declarations: [
  GoalListComponent,
  GoalGridComponent,
  GoalDetailComponent,
  GoalHomeComponent,
 ],
 imports: [CommonModule, GoalRoutingModule],
})
export class GoalModule {}
