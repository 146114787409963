import {Component, OnInit} from '@angular/core';
import {ScratchCard, SCRATCH_TYPE} from 'scratchcard-js';

@Component({
	selector: 'app-reward-home',
	templateUrl: './reward-home.component.html',
	styleUrls: ['./reward-home.component.sass'],
})
export class RewardHomeComponent implements OnInit {
	constructor() {}
	ngOnInit() {}
}
