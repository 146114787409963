import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {DatePickerDirective, DatePickerComponent} from 'ng2-date-picker';
import {Environment} from 'src/environments/environment';
import {IHealth} from 'src/app/shared/interface/health';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {getUserId} from 'src/app/shared/util/auth-utils';
import {HealthEnums} from 'src/app/shared/util/enums';
import {Location} from '@angular/common';
import {forkJoin} from 'rxjs';

@Component({
	selector: 'app-playstation-team-challange-request',
	templateUrl: './playstation-team-challange-request.component.html',
	styleUrls: ['./playstation-team-challange-request.component.scss'],
})
export class PlaystationTeamChallangeRequestComponent implements OnInit {
	public showRule: boolean[];
	public isLoading = false;
	public challenges: IHealth.Request[] = [];
	public outgoingRequestToJoinGroups = [];
	public incomingRequestToJoinGroups = [];
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {}

	ngOnInit() {
		debugger;
		this.showRule = new Array(this.challenges.length);
		this.loadChallenges();
	}

	showAccordian(i) {
		this.showRule[i] = !this.showRule[i];
	}
	private loadChallenges() {
		this.isLoading = true;
		const challenges = this._clientService.getChallengeRequest(
			'api/GroupChallenges/Requests?AdminId=' + getUserId(),
		);
		const outgoingJoinRequest = this._clientService.getChallengeRequest(
			'api/Groups/IncomingGroupRequestToUser?UserId=' + getUserId(),
		);
		const incomingJoinRequest = this._clientService.getChallengeRequest(
			'api/Groups/GetAllIncomingUserRequestToAdmin?AdminId=' + getUserId(),
		);

		const requestArray = [];

		requestArray.push(challenges);
		requestArray.push(outgoingJoinRequest);
		requestArray.push(incomingJoinRequest);

		forkJoin(requestArray).subscribe(data => {
			this.isLoading = false;
			if (data[0]) {
				this.challenges = data[0];
				this.showRule = new Array(this.challenges.length);
			}
			if (data[1]) {
				this.outgoingRequestToJoinGroups = data[1];
			}
			if (data[2]) {
				this.incomingRequestToJoinGroups = data[2];
			}
		});
	}
	public getFormattedDate(d) {
		return moment(d, 'DD-MM-YYYY').format('DD MMMM YYYY');
	}
	public startDatePassed(d) {
		let isPassed =
			moment().diff(moment(d, 'DD-MM-YYYY'), 'days', true) < 1 ? false : true;
		return isPassed;
	}

	onActionClick(data, res) {
		let postData = {
			ChallengeId: data.ChallengeId,
			ResponderId: getUserId(),
			EventResponse: parseInt(res),
		};
		this._clientService
			.putChallengeRequest('api/GroupChallenges/RespondTeamChallange', postData)
			.subscribe(data => {
				if (data) {
					this.loadChallenges();
					this._appUtils.showSnackBar({message: data});
				}
			});
	}

	onJoinActionClick(data, res) {
		let postData = {
			RequestId: data.RequstId,
			ResponderId: getUserId(),
			EventResponse: parseInt(res),
		};

		this._clientService
			.putChallengeRequest(
				'/api/Groups/RespondToAdminGroupJoiningRequest',
				postData,
			)
			.subscribe(data => {
				if (data) {
					this.loadChallenges();
					this._appUtils.showSnackBar({message: data});
				}
			});
	}
	onIncomingJoinActionClick(data, res) {
		let postData = {
			RequestId: data.RequstId,
			ResponderId: getUserId(),
			EventResponse: parseInt(res),
		};

		this._clientService
			.putChallengeRequest('/api/Groups/AdminResponseToUserRequest', postData)
			.subscribe(data => {
				if (data) {
					this.loadChallenges();
					this._appUtils.showSnackBar({message: data});
				}
			});
	}
	checkInitiatedByMe(data) {
		if (data.ChallangerGroupId == getUserId()) {
			return true;
		} else {
			return false;
		}
	}
}
