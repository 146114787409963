import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-analysis-detail',
	templateUrl: './analysis-detail.component.html',
	styleUrls: ['./analysis-detail.component.scss']
})
export class AnalysisDetailComponent implements OnInit {
	constructor() { }

	ngOnInit() { }
}
