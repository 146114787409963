import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IHealth} from 'src/app/shared/interface/health';

@Component({
	selector: 'app-trend-home',
	templateUrl: './trend-home.component.html',
	styleUrls: ['./trend-home.component.sass'],
})
export class TrendHomeComponent implements OnInit {
	public routeData: IHealth.RouteData;
	public tabData = [
		{
			link: '/trends/sleep',
			class: 'icon-sleep',
		},
		{
			link: '/trends/steps',
			class: 'icon-steps',
		},
		{
			link: '/trends/water',
			class: 'icon-waterintake',
		},
		{
			link: '/trends/calories',
			class: 'icon-calories',
		},
		{
			link: '/trends/commute',
			class: 'icon-commute',
		},
		{
			link: '/trends/meditation',
			class: 'icon-meditation',
		},
		{
			link: '/trends/sit',
			class: 'icon-sit',
		},
		{
			link: '/trends/stand',
			class: 'icon-stand',
		},
		{
			link: '/trends/activehours',
			class: 'icon-stand',
		},
	];
	constructor(private _route: ActivatedRoute) {}

	ngOnInit() {
		this.loadRouteData();
	}

	private loadRouteData(): void {
		this._route.data.subscribe(v => {
			this.routeData = <IHealth.RouteData>v;
		});
	}
}
