import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {Location} from '@angular/common';
import {IHealth} from 'src/app/shared/interface/health';
import {getUserId} from 'src/app/shared/util/auth-utils';
import * as moment from 'moment';
import {DatePickerDirective, DatePickerComponent} from 'ng2-date-picker';
import {Environment} from 'src/environments/environment';

@Component({
	selector: 'app-playstation-team-challange-add',
	templateUrl: './playstation-team-challange-add.component.html',
	styleUrls: ['./playstation-team-challange-add.component.scss'],
})
export class PlaystationTeamChallangeAddComponent implements OnInit {
	public eventData: IHealth.Event[] = [];
	public GroupName: any;
	public isSDateOpened = false;
	public isEDateOpened = false;
	public imageUrl = Environment.groupImagechallengeUrl;
	public startDate = moment()
		.add(1, 'days')
		.format('DD MMMM YYYY');
	@ViewChild('startDateDir') startDateComp: DatePickerComponent;
	@ViewChild('endDateDir') endDateComp: DatePickerComponent;
	public endDate = moment()
		.add(2, 'days')
		.format('DD MMMM YYYY');
	public userData: IHealth.User;
	public groupData: {GroupName?: string};
	public isLoading = false;
	public selectedEvent = 1;
	public dateConfig = {
		formate: 'DD MMMM YYYY',
		min: moment(this.startDate, 'DD MMMM YYYY').add(0, 'days'),
	};
	public edateConfig = {
		formate: 'DD MMMM YYYY',
		min: moment(this.startDate, 'DD MMMM YYYY').add(2, 'days'),
	};
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {
		if (this._appUtils.IsJsonString(this._route.snapshot.params.data)) {
			this.groupData = JSON.parse(this._route.snapshot.params.data);
		}
	}

	ngOnInit() {}
	toggleSDate() {
		// this.isSDateOpened = !this.isSDateOpened
		this.startDateComp.api.open();
	}
	toggleEDate() {
		// this.isEDateOpened = !this.isEDateOpened
		this.endDateComp.api.open();
	}
	selectEvent(data) {
		this.eventData.forEach(d => (d['selected'] = 0));
		data.selected = 1;
	}
	sDChange($event) {
		if ($event) {
			console.log('Start date', $event);
			this.isFutureDate();
			this.startDate = $event.format('DD MMMM YYYY');
			this.edateConfig.min = moment(this.startDate, 'DD MMMM YYYY').add(
				1,
				'days',
			);
			this.endDate = this.edateConfig.min.format('DD MMMM YYYY');
		}
	}
	sEChange($event) {
		if ($event) {
			console.log('End Date', $event);
			this.isFutureDate();
			this.endDate = $event.format('DD MMMM YYYY');
		}
	}
	public createChallenge(e: MouseEvent) {
		e.preventDefault();
		let data = {
			EventId: this.selectedEvent,
			StartDate: moment(this.startDate, 'DD MMMM YYYY').format(
				'DD-MM-YYYY HH:MM:ss',
			),
			EndDate: moment(this.endDate, 'DD MMMM YYYY').format(
				'DD-MM-YYYY HH:MM:ss',
			),
			ChallangerGroupId: this.groupData['ChallengerGroupId'],
			OpponentGroupId: this.groupData['GroupId'],
		};
		this.isLoading = true;
		this._clientService
			.postChallengeRequest('api/GroupChallenges', data)
			.subscribe(data => {
				this.isLoading = true;
				if (data) {
					this._appUtils.showSnackBar({
						message: 'Challenge created successfully',
					});

					this._router.navigate(['/challenge/fitboard/team/challenges/', {
						data: JSON.stringify({
							url: 'Challenges/PaticipatedByUser',
							text: 'Challenges Participated'
						})
					}]);
					// this._router.navigate([
					// 	'/challenge/playstation/team/challenge/request',
					// ]);
				}
			});
	}

	public isFutureDate() {
		let bool =
			moment(this.endDate, 'DD MMMM YYYY').diff(
				moment(this.startDate, 'DD MMMM YYYY'),
				'days',
				true,
			) > 0;
		if (!bool) {
			this._appUtils.showSnackBar({
				message: 'End date cannot be less than or equal to start date',
				type: 'info',
			});
			this.endDate = moment(this.startDate, 'DD MMMM YYYY')
				.add(1, 'days')
				.format('DD MMMM YYYY');
		}
	}
	public setSelectedEvent(id) {
		this.selectedEvent = id;
	}
}
