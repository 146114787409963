import {Component, OnInit} from '@angular/core';
import {ScratchCard, SCRATCH_TYPE} from 'scratchcard-js';

@Component({
	selector: 'app-reward-card',
	templateUrl: './reward-card.component.html',
	styleUrls: ['./reward-card.component.sass'],
})
export class RewardCardComponent implements OnInit {
	isScratchModalOpen = false;
	constructor() {}
	private initScratchCard() {
		const scractCardMessage = 'Better luck next time';

		const scContainer = document.getElementById('js--sc--container');
		const sc = new ScratchCard('#js--sc--container', {
			scratchType: SCRATCH_TYPE.SPRAY,
			containerWidth: scContainer.offsetWidth,
			containerHeight: 140,
			imageForwardSrc: 'assets/imgs/scratch-bg.png',
			htmlBackground: `<h2>${scractCardMessage}</h2>`,
			clearZoneRadius: 50,
			nPoints: 30,
			pointSize: 20,
			callback: function() {},
		});

		// Init
		sc.init()
			.then(() => {
				sc.canvas.addEventListener('scratch.move', () => {
					let percent = sc.getPercent().toFixed(2);
				});
			})
			.catch(error => {
				// image not loaded
				console.debug(error.message);
			});
	}

	ngOnInit() {
		this.initScratchCard();
	}

	public openScratchModal() {
		this.isScratchModalOpen = true;
	}

	public closeScratchModal() {
		this.isScratchModalOpen = false;
	}
}
