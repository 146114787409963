import {Component, OnInit} from '@angular/core';
import * as Chart from 'chart.js';
import {IHealth} from 'src/app/shared/interface/health';
import {ChartUtilService} from 'src/app/shared/util/chart-util.service';
import {ClientService} from 'src/app/shared/http/client.service';
import {HttpClient} from '@angular/common/http';
import {Environment} from 'src/environments/environment';
import {HealthEnums} from 'src/app/shared/util/enums';
import {ActivatedRoute} from '@angular/router';

@Component({
	selector: 'app-trend-chart',
	templateUrl: './trend-chart.component.html',
	styleUrls: ['./trend-chart.component.sass'],
})
export class TrendChartComponent implements OnInit {
	public chart = [];
	public frequencies = HealthEnums.Frequency;
	public selectedFrequency: HealthEnums.Frequency;
	public routeData: IHealth.RouteData;

	constructor(
		private _helathUtil: ChartUtilService,
		private _client: ClientService,
		private _http: HttpClient,
		private _route: ActivatedRoute,
	) {}

	ngOnInit() {
		this.loadRouteData();
	}

	private loadRouteData(): void {
		this._route.data.subscribe(v => {
			this.routeData = <IHealth.RouteData>v;
			this.loadData(HealthEnums.Frequency.Daily);
		});
	}

	/**
	 * On click of frequency Weekly/Daily and yearly
	 */
	public loadData(frequency: HealthEnums.Frequency): void {
		if (this.selectedFrequency != frequency) {
			this.selectedFrequency = frequency;
			this._helathUtil
				.getdata(this.routeData.tabName, frequency, HealthEnums.PageType.Trend)
				.subscribe((response: IHealth.ChartDataPoint) => {
					var parsedData = response;
					this.renderChart(parsedData);
				});
		}
	}

	private renderChart(parsedData: IHealth.ChartDataPoint): void {
		this.chart = new Chart('canvas', {
			type: 'line',
			data: {
				labels: parsedData.labels,
				datasets: [
					{
						label: parsedData.legend,
						data: parsedData.curr,
						backgroundColor: 'transparent',
						borderColor: this.routeData.colorPrimary,
						borderWidth: 2,
						showLines: false,
					},
					{
						label: parsedData.vsLegend,
						data: parsedData.prev,
						backgroundColor: 'transparent',
						borderColor: this.routeData.colorSecondary,
						borderWidth: 2,
					},
				],
			},
			options: {
				showLine: false,
				showLabel: false,
				legend: {
					onClick: () => {},
					position: 'bottom',
					labels: {
						boxWidth: 10,
					},
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								drawBorder: true,
								// color: "rgba(0, 0, 0, 0)"
							},
						},
					],
					yAxes: [
						{
							scaleLabel: {
								display: true,
								labelString: this.routeData.unit,
							},
							gridLines: {
								drawBorder: true,

								// color: "rgba(0, 0, 0, 0)"
							},
						},
					],
				},
			},
		});
	}
}
