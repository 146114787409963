import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TrendModule} from './trend/trend.module';
import {GraphModule} from './graph/graph.module';
import {RewardModule} from './reward/reward.module';
import {EhrModule} from './ehr/ehr.module';
import {SnackbarComponent} from './utility/snackbar/snackbar.component';
import {AlertDialogComponent} from './utility/alert-dialog/alert-dialog.component';
import {LinksComponent} from './links/links.component';
import {HealthPrintModule} from './health-print/health-print.module';
import {ProfileModule} from './profile/profile.module';
import {AnalysisModule} from './analysis/analysis.module';
import {GoalModule} from './goal/goal.module';
import {PlaystationModule} from './playstation/playstation.module';
import {ActionsModule} from './actions/actions.module';
import {ChallengeModule} from './challenge/challenge.module';
// import { NgDatepickerModule } from 'ng2-datepicker';

@NgModule({
 declarations: [
  AppComponent,
  SnackbarComponent,
  AlertDialogComponent,
  LinksComponent,
 ],
 imports: [
  BrowserModule,
  AppRoutingModule,
  TrendModule,
  GraphModule,
  RewardModule,
  EhrModule,
  HealthPrintModule,
  ProfileModule,
  ChallengeModule,
  AnalysisModule,
  GoalModule,
  PlaystationModule,
  ActionsModule,
 ],
 providers: [],
 bootstrap: [AppComponent],
})
export class AppModule {}
