import {Component, OnInit, Input} from '@angular/core';

@Component({
 selector: 'app-label-value-card',
 templateUrl: './label-value-card.component.html',
 styleUrls: ['./label-value-card.component.scss'],
})
export class LabelValueCardComponent implements OnInit {
 @Input() label: string = 'No Label';
 @Input() value: string = 'No Label';
 @Input() url: string = 'No Label';
 @Input() data;
 @Input() bg = 'white';
 @Input() color = 'black';
 constructor() {}

 ngOnInit() {}
}
