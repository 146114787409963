import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/shared/http/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtilsService } from 'src/app/shared/util/app-utils.service';
import { Location } from '@angular/common';
import { IHealth } from 'src/app/shared/interface/health';
import * as moment from 'moment';
import { getUserId } from 'src/app/shared/util/auth-utils';
import { HealthEnums } from 'src/app/shared/util/enums';
@Component({
	selector: 'app-challenge-requests',
	templateUrl: './challenge-requests.component.html',
	styleUrls: ['./challenge-requests.component.scss'],
})
export class ChallengeRequestsComponent implements OnInit {
	public showRule: boolean[];
	public isLoading = false;
	public challenges: IHealth.Request[] = [];
	constructor(private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService) { }

	ngOnInit() {
		this.showRule = new Array(this.challenges.length);
		this.loadChallenges();
	}

	showAccordian(i) {
		this.showRule[i] = !this.showRule[i];
	}
	private loadChallenges() {
		this.isLoading = true;
		let postData = {
			OpponentId: getUserId(),
		};
		this._clientService
			.postChallengeRequest('api/Challenges/Requests', postData)
			.subscribe(
				data => {
					if (data) {
						this.isLoading = false;
						this.challenges = data;
						this.showRule = new Array(this.challenges.length);
					}
				},
				err => {
					this.isLoading = false;
					this._appUtils.showSnackBar(<IHealth.ISnackBar>(
						(<unknown>{ message: 'Error occurred', type: HealthEnums.Snackbar })
					));
				},
			);
	}
	public getFormattedDate(d) {
		return moment(d, 'DD-MM-YYYY').format('DD MMMM YYYY');
	}
	public startDatePassed(d) {
		let isPassed =
			moment().diff(moment(d, 'DD-MM-YYYY'), 'days', true) < 1 ? false : true;
		return isPassed;
	}

	onActionClick(data, res) {
		let postData = {
			ChallengeId: data.ChallengeId,
			ResponderId: data.OpponentId,
			EventResponse: parseInt(res),
		};
		this._clientService
			.putChallengeRequest('api/Challenges/RespondChallange', postData)
			.subscribe(data => {
				if (data) {
					this.loadChallenges();
					this._appUtils.showSnackBar({ message: data });
				}
			});
	}
	checkInitiatedByMe(data) {
		if (data.ChallangerId == getUserId()) {
			return true;
		} else {
			return false;
		}
	}
}
