import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EhrRoutingModule} from './ehr-routing.module';
import {FamiliyMemberAddComponent} from './familiy-member-add/familiy-member-add.component';
import {FamiliyMemberListComponent} from './familiy-member-list/familiy-member-list.component';
import {FamiliyMemberDetailComponent} from './familiy-member-detail/familiy-member-detail.component';
import {ReportAddComponent} from './report-add/report-add.component';
import {ReportListComponent} from './report-list/report-list.component';
import {ReportDetailComponent} from './report-detail/report-detail.component';
import {EhrHomeComponent} from './ehr-home/ehr-home.component';
import {UtilityModule} from '../utility/utility.module';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
 declarations: [
  FamiliyMemberAddComponent,
  FamiliyMemberListComponent,
  FamiliyMemberDetailComponent,
  ReportAddComponent,
  ReportListComponent,
  ReportDetailComponent,
  EhrHomeComponent,
 ],
 imports: [CommonModule, EhrRoutingModule, UtilityModule, ReactiveFormsModule],
})
export class EhrModule {}
