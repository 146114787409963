import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActionForTodayComponent } from './action-for-today/action-for-today.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/actions-for-today',
		pathMatch: 'full',
	},
	{
		path: 'actions-for-today',
		component: ActionForTodayComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ActionsRoutingModule { }
