import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {ClientService} from '../../shared/http/client.service';
import * as moment from 'moment';
@Component({
 selector: 'app-challenge-home',
 templateUrl: './challenge-home.component.html',
 styleUrls: ['./challenge-home.component.scss'],
})
export class ChallengeHomeComponent implements OnInit {
 public routeColorMatcher: string = '#00C0F3';
 public statsColor: string = '#00C0F3';
 public proute = '/challenge/fitboard';
 enableBack = true;
 constructor(private router: Router, public clientService: ClientService) {
  this.router.events.subscribe((v: NavigationEnd) => {
   if (v.url == '/challenge/active') {
    this.routeColorMatcher = '#00C0F3';
    this.statsColor = '#fdb92a';
   } else if (v.url && v.url.includes('/challenge/addnew')) {
    this.routeColorMatcher = '#fff';
    this.statsColor = '#e57916';
   } else {
    this.routeColorMatcher = 'transparent';
    this.statsColor = '#00bff5';
   }
   if (v.url && v.url.includes('playstation')) {
    this.proute = '/challenge/playstation';
   }
   if (v.url && v.url.includes('fitboard')) {
    this.proute = '/challenge/fitboard';
   }
  });
 }

 ngOnInit() {
  if ('user' in localStorage) {
   try {
    let user = JSON.parse(localStorage.getItem('user'));
    if(moment().diff(moment(user.TokenEntity.ExpiresOn,'DD-MM-YYYY'),'days',true) > 0 ){
     // this.setUser()
    }
   } catch {
    // this.setUser()
   }
  }
 }
}
