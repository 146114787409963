import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientService } from 'src/app/shared/http/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtilsService } from 'src/app/shared/util/app-utils.service';
import { Location } from '@angular/common';
import { IHealth } from 'src/app/shared/interface/health';
import { getUserId } from 'src/app/shared/util/auth-utils';
import * as moment from 'moment';
import { DatePickerDirective, DatePickerComponent } from 'ng2-date-picker';
import { Environment } from 'src/environments/environment';
@Component({
	selector: 'app-challenge-addnew',
	templateUrl: './challenge-addnew.component.html',
	styleUrls: ['./challenge-addnew.component.scss'],
})
export class ChallengeAddnewComponent implements OnInit {
	public eventData: IHealth.Event[] = [];
	public isSDateOpened = false;
	public isEDateOpened = false;
	public imageUrl = Environment.challengeUrl;
	public startDate = moment().add(1, 'days').format('DD MMMM YYYY');
	@ViewChild('startDateDir') startDateComp: DatePickerComponent;
	@ViewChild('endDateDir') endDateComp: DatePickerComponent;
	public endDate = moment().add(2, 'days').format('DD MMMM YYYY');
	public userData: IHealth.User;
	public isLoading = false;
	public dateConfig = {
		formate: 'DD MMMM YYYY',
		min: moment(this.startDate, 'DD MMMM YYYY').add(0, 'days'),
	};
	public edateConfig = {
		formate: 'DD MMMM YYYY',
		min: moment(this.startDate, 'DD MMMM YYYY').add(2, 'days'),
	};
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) { }

	ngOnInit() {
		this.loadEvents();
		this._route.queryParams.subscribe(params => {
			console.debug(params);
			if (params && params.params && this.IsJsonString(params.params)) {
				this.userData = JSON.parse(params.params);
			}
		});
	}
	private IsJsonString(str) {
		try {
			var json = JSON.parse(str);
			return typeof json === 'object';
		} catch (e) {
			return false;
		}
	}

	public loadEvents() {
		this.isLoading = true;
		this._clientService.getChallengeRequest('api/Events').subscribe(data => {
			if (data) {
				this.isLoading = false;
				this.eventData = data;
			}
		});
	}
	toggleSDate() {
		// this.isSDateOpened = !this.isSDateOpened
		this.startDateComp.api.open();
	}
	toggleEDate() {
		// this.isEDateOpened = !this.isEDateOpened
		this.endDateComp.api.open();
	}
	selectEvent(data) {
		this.eventData.forEach(d => (d['selected'] = 0));
		data.selected = 1;
	}
	sDChange($event) {
		if ($event) {
			console.log('Start date', $event);
			this.isFutureDate();
			this.startDate = $event.format('DD MMMM YYYY');
			this.edateConfig.min = moment(this.startDate, 'DD MMMM YYYY').add(
				1,
				'days',
			);
			this.endDate = this.edateConfig.min.format('DD MMMM YYYY');
		}
	}
	sEChange($event) {
		if ($event) {
			console.log('End Date', $event);
			this.isFutureDate();
			this.endDate = $event.format('DD MMMM YYYY');
		}
	}
	public createChallenge(e: MouseEvent) {
		e.preventDefault();
		let event = this.eventData.find(d => d['selected'] == 1);
		if (!event) {
			this._appUtils.showSnackBar({
				message: 'Please select event type.',
				type: 'info',
			});
			return;
		}
		let data = {
			EventTypeId: 1,
			EventId: event.Id,
			StartDate: moment(this.startDate, 'DD MMMM YYYY').format(
				'DD-MM-YYYY HH:MM:ss',
			),
			EndDate: moment(this.endDate, 'DD MMMM YYYY').format(
				'DD-MM-YYYY HH:MM:ss',
			),
			ChallangerId: getUserId(),
			OpponentId: this.userData.Id,
			IsPublc: true,
			IsActive: true,
		};
		this._clientService
			.postChallengeRequest('api/Challenges', data)
			.subscribe(data => {
				if (data) {
					this._appUtils.showSnackBar({ message: 'Challenge created successfully' });

					this._router.navigate(['/challenge/fitboard/individual/challenges/', {
						data: JSON.stringify({
							url: 'Challenges/PaticipatedByUser',
							text: 'Challenges Participated'
						})
					}]);
				}
			});
	}

	public isFutureDate() {
		let bool = moment(this.endDate, 'DD MMMM YYYY').diff(moment(this.startDate, 'DD MMMM YYYY'), 'days', true) > 0
		if (!bool) {
			this._appUtils.showSnackBar({
				message: 'End date cannot be less than or equal to start date',
				type: 'info'
			});
			this.endDate = moment(this.startDate, 'DD MMMM YYYY').add(1, 'days').format('DD MMMM YYYY');
		}
	}
}
