import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IHealth} from '../shared/interface/health';
import {GraphMessaging} from '../shared/util/constants';
import {RewardHomeComponent} from './reward-home/reward-home.component';

const routes: Routes = [
 {
  path: '',
  redirectTo: '/reward',
  pathMatch: 'full',
 },
 {
  path: 'reward',
  component: RewardHomeComponent,
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class RewardRoutingModule {}
