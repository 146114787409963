import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IHealth} from '../shared/interface/health';
import {TrendMessaging} from '../shared/util/constants';
import {HealthPrintHomeComponent} from './health-print-home/health-print-home.component';
import {HealthScoreComponent} from './health-score/health-score.component';
import {BmiComponent} from './bmi/bmi.component';
import {StepsComponent} from './steps/steps.component';
import {SleepComponent} from './sleep/sleep.component';

const routes: Routes = [
 {
  path: '',
  redirectTo: '/health-print',
  pathMatch: 'full',
 },
 {
  path: 'health-print',
  component: HealthPrintHomeComponent,
  children: [
   {
    path: 'health-score',
    component: HealthScoreComponent,
   },
   {
    path: 'bmi',
    component: BmiComponent,
   },
   {
    path: 'steps',
    component: StepsComponent,
   },
   {
    path: 'sleep',
    component: SleepComponent,
   },
  ],
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class HealthPrintRoute {}
