import {Component, OnInit, Input} from '@angular/core';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import {HealthPrintUtilService} from 'src/app/shared/util/health-print.service';
import {HealthEnums} from 'src/app/shared/util/enums';
import {StepsComponent} from '../steps/steps.component';

@Component({
	selector: 'app-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
	@Input('inputData') inputData: any = <any>{};
	counter = Array;
	public chart = [];
	public data = [];
	constructor(private _helathUtil: HealthPrintUtilService) {}

	ngOnInit() {
		this.loaddata();
	}

	public loaddata() {
		console.log('######################################');
		console.log(this.inputData.url);
		this._helathUtil.getdata(this.inputData.url).subscribe(response => {
			this.data = response;
			setTimeout(() => {
				this.renderChart(response);
			});
		});
	}

	private renderChart(data): void {
		console.log('-------------------------------------');
		console.log(JSON.stringify(data));
		console.log('-------------------------------------');
		let currentMonth = this.getWeeklyData(data[0]).splice(0, 5);
		let lastMonth = this.getWeeklyData(data[1]).splice(0, 5);

		console.log('currentMonth = '+currentMonth);
		console.log('lastMonth = '+lastMonth);
		let chartData = {
			type: 'line',
			data: {
				labels: [1, 2, 3, 4, 5],
				datasets: [
					{
						label:
							data[0].dataPoints.length && data[0].dataPoints[0].date
								? this.getFormatedDate(data[0].dataPoints[0].date)
								: 'NA',
						data: currentMonth,
						backgroundColor: 'transparent',
						borderColor: this.inputData.borderColor[0],
						borderWidth: 2,
						showLines: false,
					},
					{
						label:
							data[1].dataPoints.length && data[1].dataPoints[0].date
								? this.getFormatedDate(data[1].dataPoints[0].date)
								: 'NA',
						data: lastMonth,
						backgroundColor: 'transparent',
						borderColor: this.inputData.borderColor[1],
						borderWidth: 2,
						showLines: false,
					},
				],
			},
			options: {
				showLine: false,
				showLabel: false,
				legend: {
					display: false,
				},
				scales: {
					xAxes: [
						{
							barPercentage: 0.5,
							barThickness: 6,
							maxBarThickness: 20,
							minBarLength: 2,
							gridLines: {
								color: 'rgba(0, 0, 0, 0)',
							},
							scaleLabel: {
								display: true,
								labelString: 'Week',
							},
						},
					],
					yAxes: [
						{
							gridLines: {
								color: 'rgba(0, 0, 0, 0)',
							},
							scaleLabel: {
								display: true,
								labelString: this.inputData.label,
							},
						},
					],
				},
			},
		};
		this.chart = new Chart(this.inputData.label, chartData);
	}
	public getWeeklyData(data) {
		var weeks = {};
		data.dataPoints.forEach(v => {
			let w = this.weekOfMonth(moment(v.date, 'DD/MM/YYYY'));
			weeks[w] = weeks[w] ? [...weeks[w], v.point] : [v.point];
		});
		let groupedData = [];
		for (let key in weeks) {
			groupedData.push(weeks[key].reduce((a, b) => a + b, 0));
		}
		return groupedData;
	}
	public weekOfMonth(input) {
		const firstDayOfMonth = input.clone().startOf('month');
		const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

		const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

		return Math.ceil((input.date() + offset) / 7);
	}
	public getFormatedDate(date: string): string {
		return moment(date, 'DD/MM/YYYY').format('MMM');
	}
	public getFormatedDay(date: string): string {
		return moment(date, 'DD/MM/YYYY').format('DD');
	}

	public getTotalSteps() {
		let total = {
			currentMonth: 0,
			lastMonth: 0,
		};
		const data = this.data;
		if (data.length && data[0].dataPoints.length) {
			total.currentMonth = data[0].dataPoints.reduce((a, b) => a + b.point, 0);
		}
		if (data.length && data[1].dataPoints.length) {
			total.lastMonth = data[1].dataPoints.reduce((a, b) => a + b.point, 0);
		}
		return total;
	}

	public getStepsDiffrence() {
		const {
			currentMonth,
			lastMonth,
		}: {currentMonth: number; lastMonth: number} = this.getTotalSteps();
		const prefix = lastMonth < currentMonth ? '+' : '';
		return `${prefix} ${currentMonth - lastMonth}`;
	}
	public getCount(v) {
		let arr = [];
		for (let i = 0; i < v; i++) {
			arr.push(i);
		}
		return arr;
	}
}
