export namespace HealthEnums {
	/**
	 * trends and Graphs freuencies
	 */
	export enum Frequency {
		Daily = 'Daily',
		Weekly = 'Weekly',
		Monthly = 'Monthly',
	}
	export enum PageType {
		Trend = 'fetchtrends',
		Graph = 'fetchgraph',
	}
	export enum HealthPrint {
		steps = 'steps',
		sleep = 'sleep',
		stand = 'stand',
		water = 'water',
		med = 'meditation',
		sit = 'sit',
		bmi = 'bmi',
		analysis = 'analysis',
		activehours = 'activehours',
	}
	export enum Snackbar {
		info,
		danger,
		success,
	}
}
