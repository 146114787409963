import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TrendChartComponent} from './trend-chart/trend-chart.component';
import {TrendHomeComponent} from './trend-home/trend-home.component';
import {IHealth} from '../shared/interface/health';
import {TrendMessaging} from '../shared/util/constants';

const routes: Routes = [
 {
  path: '',
  redirectTo: '/trends',
  pathMatch: 'full',
 },
 {
  path: 'trends',
  component: TrendHomeComponent,
  children: [
   {
    path: 'sleep',
    component: TrendChartComponent,
    data: <IHealth.RouteData>TrendMessaging.sleep,
   },
   {
    path: 'steps',
    component: TrendChartComponent,
    data: <IHealth.RouteData>TrendMessaging.steps,
   },
   {
    path: 'water',
    component: TrendChartComponent,
    data: <IHealth.RouteData>TrendMessaging.water,
   },
   {
    path: 'calories',
    component: TrendChartComponent,
    data: <IHealth.RouteData>TrendMessaging.calories,
   },
   {
    path: 'commute',
    component: TrendChartComponent,
    data: <IHealth.RouteData>TrendMessaging.commute,
   },
   {
    path: 'meditation',
    component: TrendChartComponent,
    data: <IHealth.RouteData>TrendMessaging.meditation,
   },
   {
    path: 'sit',
    component: TrendChartComponent,
    data: <IHealth.RouteData>TrendMessaging.sit,
   },
   {
    path: 'stand',
    component: TrendChartComponent,
    data: <IHealth.RouteData>TrendMessaging.stand,
   },
  ],
 },
];

@NgModule({
 imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule],
})
export class TrendRoutingModule {}
