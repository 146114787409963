import {Component, OnInit, Input} from '@angular/core';
import {getUserId} from 'src/app/shared/util/auth-utils';
import {Environment} from 'src/environments/environment';

@Component({
	selector: 'app-playstation-team-item',
	templateUrl: './playstation-team-item.component.html',
	styleUrls: ['./playstation-team-item.component.scss'],
})
export class PlaystationTeamItemComponent implements OnInit {
	@Input() data: {
		GroupName?: string;
		MemberName?: string;
		GroupAdminId?: number;
		IsAdmin?: boolean;
	} = {};
	@Input() isGroup: boolean = true;
	public isActive?: boolean;

	constructor() {}

	ngOnInit() {}
	public imageUrl(): string {
		let p = this.data['ImagePath'];
		if (!this.isGroup) {
			p = this.data['MemberImagePath'];
			 return Environment.individualImagechallengeUrl + p;
		}
		return Environment.groupImagechallengeUrl + p;
	}
	public get userId(): number {
		return getUserId();
	}
	public getUserType() {
		this.isGroup
			? this.data.GroupAdminId == this.userId
				? 'Admin'
				: 'Member'
			: this.data.IsAdmin == !!this.userId
			? 'Admin'
			: 'Member';
	}
}
