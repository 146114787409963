import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ClientService} from 'src/app/shared/http/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilsService} from 'src/app/shared/util/app-utils.service';
import {getUserId, getCorporateId} from 'src/app/shared/util/auth-utils';
import {Environment} from 'src/environments/environment';
@Component({
	selector: 'app-playstation-team-selected',
	templateUrl: './playstation-team-selected.component.html',
	styleUrls: ['./playstation-team-selected.component.scss'],
})
export class PlaystationTeamSelectedComponent implements OnInit {
	public data: {
		GroupMmebers?: string;
		GroupName?: string;
	} = {};
	public groupId;
	public type = 1;
	@ViewChild('img') img;
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) {}

	ngOnInit() {
		this._route.params.subscribe(params => {
			console.log('Recieved paramters: ', params['id']);
			this.groupId = params['id'];
			this.loasSelectedGroupData(params['id']);
		});
	}

	public imageUrl(): string {
		let p = this.data['ImagePath'];
		return Environment.groupImagechallengeUrl + p;
	}

	private loasSelectedGroupData(id) {
		this._clientService
			.getChallengeRequest('api/Groups?GroupId=' + id)
			.subscribe(res => {
				this.data = res;
				this.type = this.data['IsPublc'] ? 1 : 0;
			});
	}
	public changeType($event) {
		this.type = parseInt($event.target.value);
	}

	getBase64Image(img) {
		var canvas = document.createElement('canvas');
		canvas.width = img.width;
		canvas.height = img.height;
		var ctx = canvas.getContext('2d');
		ctx.drawImage(img, 0, 0);
		var dataURL = canvas.toDataURL('image/png');
		return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
	}
	public postCreateTeam() {
		let postData = {
			Id: this.groupId,
			CorporateId: getCorporateId(),
			GroupName: this.data['GroupName'],
			Picturbase64string: this.getBase64Image(this.img.nativeElement),
			GroupAdminId: getUserId(),
			IsPublc: this.type ? true : false,
			CreatedBy: getUserId(),
		};
		this._clientService
			.putChallengeRequest('api/Groups/UpdateGroup', postData)
			.subscribe(res => {
				if (res) {
					this._appUtils.showSnackBar({
						message: 'Team updated successfully',
						type: 'success',
					});
					this._router.navigate([
						'/challenge/playstation/team/add-member/',
						{groupId: res},
					]);
				}
			});
	}
	editTeam() {
		this._router.navigate([
			'/challenge/playstation/team/edit/',
			{groupId: this.groupId},
		]);
	}
	addTeam() {
		this._router.navigate([
			'/challenge/playstation/team/add-member/',
			{groupId: this.groupId},
		]);
	}
	public get isAdmin(): boolean {
		return this.data['GroupAdminId'] == getUserId();
	}
}
