import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScratchCardComponent} from './scratch-card/scratch-card.component';
import {BadgeComponent} from './badge/badge.component';
import {RewardHomeComponent} from './reward-home/reward-home.component';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {RewardRoutingModule} from './reward-routing.module';
import {LeaderboardComponent} from './leaderboard/leaderboard.component';
import {RewardCardComponent} from './reward-card/reward-card.component';
import {RewardContainerComponent} from './reward-container/reward-container.component';

@NgModule({
 declarations: [
  ScratchCardComponent,
  BadgeComponent,
  RewardHomeComponent,
  LeaderboardComponent,
  RewardCardComponent,
  RewardContainerComponent,
 ],
 imports: [HttpClientModule, BrowserModule, CommonModule, RewardRoutingModule],
 exports: [RewardContainerComponent],
})
export class RewardModule {}
