import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-compete',
	templateUrl: './compete.component.html',
	styleUrls: ['./compete.component.scss'],
})
export class CompeteComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
