import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnalysisHomeComponent} from './analysis-home/analysis-home.component';
import {AnalysisRoutingModule} from './profile-routing.module';
import {AnalysisDetailComponent} from './analysis-detail/analysis-detail.component';

@NgModule({
 declarations: [AnalysisHomeComponent, AnalysisDetailComponent],
 imports: [CommonModule, AnalysisRoutingModule]
})
export class AnalysisModule {}
