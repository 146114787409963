import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IHealth } from 'src/app/shared/interface/health';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientService } from 'src/app/shared/http/client.service';
import * as moment from 'moment';
import { AppUtilsService } from 'src/app/shared/util/app-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import { getAuthUUID } from 'src/app/shared/util/auth-utils';

@Component({
	selector: 'app-familiy-member-add',
	templateUrl: './familiy-member-add.component.html',
	styleUrls: ['./familiy-member-add.component.sass'],
})
export class FamiliyMemberAddComponent implements OnInit {
	@ViewChild('fileInput') fileInput;

	private familyObject: IHealth.IFamilyMemberList = <
		IHealth.IFamilyMemberList
		>{};
	private copyObject: IHealth.IFamilyMemberList = <IHealth.IFamilyMemberList>{};
	public familyForm: FormGroup;
	public isLoading: boolean = false;
	public relationTypeData: IHealth.IRelationType;
	constructor(
		private _clientService: ClientService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _appUtils: AppUtilsService,
	) { }

	ngOnInit() {
		this._route.queryParams.subscribe(params => {
			console.debug(params);
			if (params && params.params && this.IsJsonString(params.params)) {
				this.familyObject = JSON.parse(params.params);
				this.copyObject = { ...this.familyObject };
			}
			this.loadDropDownData();
		});
		document.body.classList.remove('ehr');
	}
	private loadDropDownData() {
		this.isLoading = true;
		forkJoin(
			this._clientService.postRequest('FetchAllClientRelation', null),
		).subscribe(response => {
			this.isLoading = false;
			this.relationTypeData = response[0];
			this.createForm();
		});
	}
	private IsJsonString(str) {
		try {
			var json = JSON.parse(str);
			return typeof json === 'object';
		} catch (e) {
			return false;
		}
	}
	private createForm() {
		this.familyForm = new FormGroup({
			name: new FormControl(this.copyObject.Name, [
				Validators.required,
				Validators.minLength(2),
				Validators.maxLength(100),
			]),
			mobile: new FormControl(this.copyObject.Mobile, [
				Validators.required,
				Validators.maxLength(10),
				Validators.minLength(10),
				Validators.pattern(/^[6-9]\d{9}$/),
			]),
			dob: new FormControl(
				moment(this.copyObject.DateOfBirth).format('YYYY-MM-DD'),
				Validators.required,
			),
			gender: new FormControl(this.copyObject.Gender, Validators.required),
			email: new FormControl(this.copyObject.Email, [
				Validators.required,
				Validators.email,
			]),
			imagePath: new FormControl(''),
			relationId: new FormControl(this.copyObject.RelationId),
		});
	}

	public submitForm() {
		this.isLoading = true;
		let fi = this.fileInput.nativeElement;
		let fileToUpload = null;
		if (fi.files && fi.files[0]) {
			fileToUpload = fi.files[0];
			var form = new FormData();
			form.append('FamilyProfileUpload', fileToUpload);
			this._clientService.uploadFile('UploadFiles', form).subscribe(
				(response: IHealth.IFileRespone[]) => {
					console.debug(response);
					let postData = this.createPostdata();
					postData.ImagePath = response[0].Path;
					this.isLoading = false;
					this.checkPostPatchFamilyMember(postData);
				},
				() => {
					this.isLoading = false;
				},
			);
		} else {
			let postData = this.createPostdata();
			this.checkPostPatchFamilyMember(postData);
		}
	}

	private checkPostPatchFamilyMember(postData: IHealth.IFamilyMemberList) {
		this.isLoading = true;
		if (this.familyObject.FID) {
			this.postFamilyData('UpdateFamilyMember', postData);
		} else {
			this.postFamilyData('AddFamilyMember', postData);
		}
	}

	private postFamilyData(url: string, postData: IHealth.IFamilyMemberList) {
		this._clientService.postRequest(url, postData).subscribe(
			(result: IHealth.HttpResult) => {
				if (result.StatusCode == 200) {
					this._appUtils.showSnackBar(<IHealth.ISnackBar>{
						message: result.Msg,
					});
					this.changeQuery();
					this.isLoading = false;
				}
			},
			() => {
				this.isLoading = false;
			},
		);
	}
	private changeQuery() {
		//this._router.navigate(['.'], { relativeTo: this._route, queryParams: {params:JSON.stringify(data)},replaceUrl: true });
		this._router.navigate(['/ehr/family-member-list'], { replaceUrl: true });
	}
	private createPostdata() {
		let postData: IHealth.IFamilyMemberList = <IHealth.IFamilyMemberList>{};
		postData.Name = this.familyForm.value.name;
		postData.FID = this.copyObject.FID ? this.copyObject.FID : 0;
		postData.DateOfBirth = moment(this.familyForm.value.dob).format(
			'YYYY-MM-DD',
		);
		postData.Email = this.familyForm.value.email;
		postData.Gender = this.familyForm.value.gender;
		postData.Mobile = this.familyForm.value.mobile;
		postData.ImagePath = this.copyObject.ImagePath;
		postData.CreatedBy = getAuthUUID();
		postData.RelationId = this.familyForm.value.relationId
			? this.familyForm.value.relationId
			: 10;
		return postData;
	}

	public get isFormValid(): boolean {
		if (this.familyForm.invalid) {
			return false;
		} else if (this.familyObject.FID) {
			if (this.copyObject.Name != this.familyForm.value.name) return true;
			else if (this.copyObject.Email != this.familyForm.value.email)
				return true;
			else if (
				moment(this.copyObject.DateOfBirth).format('YYYY-MM-DD') !=
				moment(this.familyForm.value.dob).format('YYYY-MM-DD')
			)
				return true;
			else if (this.copyObject.Gender != this.familyForm.value.gender)
				return true;
			else if (this.copyObject.ImagePath != this.familyForm.value.imagePath)
				return true;
			else if (this.copyObject.Mobile != this.familyForm.value.mobile)
				return true;
			else if (this.copyObject.RelationId != this.familyForm.value.relationId)
				return true;
			else {
				return false;
			}
		} else {
			return true;
		}
	}
	public get headerLable(): string {
		if (this.familyObject && this.familyObject.FID) {
			return 'Update Family Member';
		} else {
			return 'Add New Family Member';
		}
	}
}
